import * as React from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  defaultDataIdFromObject,
  IdGetter,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { HttpOptions } from "@apollo/client/link/http";
import { secondsToMilliseconds } from "date-fns";
import { apolloRetryLinkRetryIf } from "@homewisedocs/client-utils/lib/apolloClient";
import { sentryApolloErrorLink } from "@homewisedocs/client-utils/lib/monitoring/sentryApolloErrorLink";
import { assertString } from "@homewisedocs/common/lib/assertions/string";
import {
  CLIENT_APP_TYPE_HEADER_NAME,
  REQUESTOR_APP_CLIENT_APP_TYPE_HEADER_VALUE,
} from "@homewisedocs/common/lib/constants/clientAppType";
import {
  OrderStatusQuery,
  IndividualItem,
  Bundle,
  HOA,
  RushOption,
  Requestor,
} from "../../__generated__/gql";
import introspectionData from "../../__generated__/possibleTypes";
import { getOrderDataId } from "./utils";
// NOTE: if this import path breaks/changes, be sure to update the value of the
// `placeOrderMutationOperationName` variable.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PlaceOrderMutation as _PlaceOrderMutation } from "../../__generated__/gql";
const placeOrderMutationOperationName = "PlaceOrderMutation";
// [Nico, 5/15/2018] Do NOT convert this to an import. apollo-upload-client publishes with the "module"
// option in its package.json, which would cause this issue when we try to run our Cypress tests:
//
// File '/Users/nchaves/Development/requestor-app/node_modules/apollo-upload-client/lib/module/index.js' is   not under 'rootDir' '/Users/nchaves/Development/requestor-app/src'. 'rootDir' is expected to contain all source files.
//  Error: /Users/nchaves/Development/requestor-app/tsconfig.json
//
// We can avoid the error by using `require` instead of `import`. However, this means that our type declarations
// for apollo-upload-client won't get used automatically. But we're defining those types ourselves, so we just moved
// them to this file and used an explicit type annotation.
const uploadClient: ApolloUploadClientModule = require("apollo-upload-client");

/**
 * Accepts an object with a `__typename` and a list of property names indicating
 * which properties' values should be included in the cache key.
 * Returns a cache key that's ready to be provided to Apollo.
 */
export const buildCompositeApolloCacheKey = <T extends { __typename: string }>(
  o: T,
  keyPropertyNames: Array<keyof T>
) => {
  const cacheKeyInput = JSON.stringify(
    keyPropertyNames.map(keyPropertyName => o[keyPropertyName])
  );
  // `window.btoa()` only accepts ASCII input. The fields that make up this
  // cache key might contain non-ASCII characters (letters from a non-Latin
  // alphabet, emoji, symbols, etc). `encodeURIComponent` will replace these
  // problematic characters with escape sequences. MDN recommends a method using
  // `encodeURIComponent` to make non-ASCII input safe here:
  // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_2_%E2%80%93_escaping_the_string_before_encoding_it
  // We can make things a little simpler and skip the step where the escape
  // sequences are converted to bytes, since we never need to unescape this.
  // See https://github.com/homewisedocs/requestor-app/issues/1007
  const asciiCacheKeyInput = encodeURIComponent(cacheKeyInput);
  const serializedCacheKey = btoa(asciiCacheKeyInput);
  return `${o.__typename}:${serializedCacheKey}`;
};

type BundleItem = Bundle["items"][number];

type Order = Exclude<OrderStatusQuery["order"], null>;

// See `dataIdFromObject` function below for note on why this typename is
// "arbitrary" and not any string
type GenericGraphqlEntity = { __typename: "arbitrary" } & (
  | { uuid: string; id: undefined }
  | { uuid: undefined; id: string }
);

export const dataIdFromObject: IdGetter = (
  // If the object isn't any of the types explicitly listed, the `__typename`
  // might be any other string except the typenames used by those types, but
  // TypeScript doesn't treat this union like a discriminated union on the
  // `__typename` property when we use a type that includes all strings except
  // the typenames belonging to the explicitly listed types. A single
  // arbitrary string _does_ allow TypeScript to discriminate though, so
  // that's what we do.
  o:
    | RushOption
    | IndividualItem
    | Bundle
    | BundleItem
    | Order
    | GenericGraphqlEntity
) => {
  // Multiple calls to the processingOptions resolver with different
  // variables may return rush options with the same ID but different
  // values for things like price, due date, etc. This can confuse
  // the Apollo cache when it's using the default cache key, since it
  // assumes that two entities with the same ID are interchangeable. This
  // can cause Apollo to provide rush option data, for example, that
  // corresponds to an order containing an LSQ when another query is sent
  // for an order that does _not_ contain an LSQ. Include the rush
  // option's price etc. in its cache key so Apollo doesn't make this
  // mistake.
  const rushOptionTypename: RushOption["__typename"] = "RushOption";
  if (o.__typename === rushOptionTypename) {
    return buildCompositeApolloCacheKey(o, [
      "id",
      "price",
      "dueDate",
      "paymentDue",
    ]);
  }

  const individualItemTypename: IndividualItem["__typename"] = "IndividualItem";
  const bundleTypename: Bundle["__typename"] = "Bundle";
  const bundleItemTypename: BundleItem["__typename"] = "BundleItem";
  if (
    o.__typename === individualItemTypename ||
    o.__typename === bundleTypename
  ) {
    return buildCompositeApolloCacheKey(o, [
      "id",
      "label",
      "mgmtCompanyFee",
      "hwdFee",
      "paymentDue",
      "separateHwdFeeLabel",
      "description",
    ]);
  } else if (o.__typename === bundleItemTypename) {
    return buildCompositeApolloCacheKey(o, ["id", "label"]);
  }

  // Caching each type of order separately can cause problems when users
  // take actions that change the status of an order (e.g. from Unpaid to
  // Pending); we know that an order can only be in one state at a time,
  // but Apollo would happily cache both an UnpaidOrder with UUID 123 and a
  // PendingOrder with UUID 123. To avoid this, use the same typename for
  // every subtype of Order within the cache. If Apollo stores an
  // UnpaidOrder with ID UU123 and a PendingOrder with UUID 123 subsequently
  // arrives via mutation response or a query, Apollo will overwrite the
  // __typename of Order:123 from "UnpaidOrder" to "PendingOrder"
  // See https://github.com/homewisedocs/requestor-app/issues/457
  const orderTypeDictionary: Record<Order["__typename"], true> = {
    PendingOrder: true,
    CompletedOrder: true,
    UnpaidOrder: true,
    CancelledOrder: true,
  };

  if (orderTypeDictionary.hasOwnProperty(o.__typename)) {
    return getOrderDataId({ orderUUID: (o as Order).uuid });
  }

  // Unfortunately, TypeScript doesn't rule out the Order types although
  // any Order object would have already been handled by now. As a result
  // we have to cast to GenericGraphqlEntity.
  // For any types other than those above, we can use a simple cache key
  // that uses the typename and a unique ID. All our types will have
  // either a `uuid` or `id` attribute to use in the cache key.
  const genericEntity = o as GenericGraphqlEntity;

  if (genericEntity.uuid != null) {
    return `${genericEntity.__typename}:${genericEntity.uuid}`;
  }

  return defaultDataIdFromObject(genericEntity);
};

const buildApolloCache = () => {
  return new InMemoryCache({
    dataIdFromObject,
    possibleTypes: introspectionData.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          hoa: {
            // Tell Apollo that the `hoa` root resolver returns the "HOA" type.
            // Ex: if user searches by HOA unit, the root resolver is `hoaUnit`.
            // Later, we need to access the same HOA data, but using the `hoa`
            // root resolver. As a result, we need to tell Apollo how to look
            // for an HOA in the cache when using the `hoa` root resolver.
            read(_, { args, toReference }) {
              assertString(
                args?.uuid,
                `Expected hoa resolver to receive 'uuid' string arg. Received: ${args?.uuid}`
              );
              const hoaUuid = args.uuid;
              const hoaTypename: HOA["__typename"] = "HOA";
              return toReference({ __typename: hoaTypename, id: hoaUuid });
            },
          },
          requestor: {
            // Tell Apollo that the `requestor` root resolver returns the
            // `Requestor` type. This allows the ViewProfileQuery to use
            // profiles already fetched by the UsersListQuery.
            read(existing, { args, toReference }) {
              // The `requestor` resolver doesn't necessarily need a `uuid`. If
              // a requestor is querying for their own account's data, then the
              // UUID will be based off of their auth token. In that case, we
              // don't need a cache redirect anyways, because we only ever use
              // the root `requestor` resolver when querying for a requestor's
              // own data.
              const requestorUuid: string | null = args ? args.uuid : null;
              if (requestorUuid) {
                const requestorTypename: Requestor["__typename"] = "Requestor";
                return toReference({
                  __typename: requestorTypename,
                  id: requestorUuid,
                });
              }
              return existing;
            },
          },
        },
      },
      // The current owner and buyer can be modified after an order is placed,
      // so it would be better not to automatically merge the results of two
      // separate queries for those fields together. Otherwise - at least in
      // theory - we could end up combining cached data in a way that doesn't
      // make sense, such as showing a cached email address that belongs to a
      // previous buyer alongside the newly-updated buyer's name.
      // `merge: false` is apollo-client 3's default behavior, but if it's not
      // set explicitly there will be a warning printed in the console.
      // See http://web.archive.org/web/20210827152220/https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
      CurrentOwner: {
        merge: false,
      },
      Buyer: {
        merge: false,
      },
      // Shipping info for an order probably won't be changing drastically
      // enough to make merging data unsafe, but there shouldn't be any harm in
      // not merging either.
      ShippingInfo: {
        merge: false,
      },
    },
  });
};

// A link to automatically retry requests that fail due to network errors.
// The time between retries starts with the intitial delay configured below,
// then doubles with every attempt after that.
const retryLink = new RetryLink({
  delay: {
    initial: secondsToMilliseconds(0.5),
    // Max time to wait between retries
    max: secondsToMilliseconds(4),
    // With jitter enabled, the delay can be anywhere from 0 to 2x the
    // calculated delay time. This is to avoid the "thundering herd" problem
    // where the server is down for a period of time and is crushed by
    // simultaneous requests when it comes up. This doesn't seem like the kind of
    // application where that problem is a real risk, though, and it will make
    // our delays much less predictable. Let's disable it.
    jitter: false,
  },
  attempts: {
    max: 6,
    retryIf: apolloRetryLinkRetryIf({
      // Don't retry the Place Order mutation.
      // In general, it _should_ be safe to retry the Place Order mutation.
      // However, there _might_ be an edge case where the server handles the
      // mutation successfully but then fails to send the response to our load
      // balancer. In that case, we wouldn't want to re-send the mutation since
      // that would result in a duplicate order/payment. Again, this scenario
      // seems unlikely, but let's be cautious.
      // TODO: Ideally, we should set up our payment processor to detect
      // duplicate charges. That would allow us to send retries without worrying
      // about duplicate charges. However, we've been hesitant about enabling
      // duplicate detection in our payment processor. In our experience, our
      // current payment processor occasionally has infra issues that cause some
      // payments to get processed even though we never receive an API response.
      // In that case, our server returns an error to the client after the
      // payment API request times out. The user can then try placing the order
      // again. If we used duplicate detection, this 2nd request would get
      // rejected, which would frustrate the user. As a result, we currently
      // don't use duplicate detection. Note: Our server logs all payment
      // timeouts, and we manually investigate those cases to check for "dangling"
      // charges that didn't get associated with an order.
      // ? Would a token-based payment approach help ameliorate some of these
      // issues and allow us to enable retries for the Place Order mutation?
      unretryableOperationNames: [placeOrderMutationOperationName],
    }),
  },
});

// As of April 2019, this upload link accepts the same options as Apollo's http
// link: https://github.com/jaydenseric/apollo-upload-client#function-createuploadlink
type UploadLinkOptions = HttpOptions;
interface ApolloUploadClientModule {
  createUploadLink(options?: UploadLinkOptions): any;
}
const uploadLink = uploadClient.createUploadLink({
  uri: "/graphql",
  // The `fetch` implementation in older browsers may not send cookies along
  // with requests, even at the same origin, unless explicitly directed.
  credentials: "same-origin",
  // Send a header indicating the request is from the requestor app.
  headers: {
    [CLIENT_APP_TYPE_HEADER_NAME]: REQUESTOR_APP_CLIENT_APP_TYPE_HEADER_VALUE,
  },
});

const buildApolloClient = () => {
  const client = new ApolloClient({
    // Apollo Client passes this value in a header that Apollo Server sends to
    // Apollo Studio so that it can identify which client sent the operation.
    // See docs: https://www.apollographql.com/docs/studio/metrics/client-awareness/#using-apollo-server-and-apollo-client
    // TODO: Consider specifying `version` too.
    name: REQUESTOR_APP_CLIENT_APP_TYPE_HEADER_VALUE,
    // ! Always make sure that the last link in this array is a terminating link
    link: ApolloLink.from([sentryApolloErrorLink, retryLink, uploadLink]),
    cache: buildApolloCache(),
  });

  return client;
};

export const CustomApolloProvider: React.FC = ({ children }) => {
  // Use `useRef` hook to instantiate the client once when the provider mounts
  const client = React.useRef(buildApolloClient());

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};
