import * as React from "react";
import { Menu, MenuItem, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { defaultNavbarFontSize, logoWidth } from "./shared";
import { Button } from "@homewisedocs/components/lib/Button";
import { UserAvatar } from "@homewisedocs/components/lib/GenericNavbar/UserAvatar";
import { WelcomeMessage } from "@homewisedocs/components/lib/GenericNavbar/WelcomeMessage";
import { MY_ACCOUNT } from "../../constants/routes";
import { AuthMenuDataTest } from "./dataTest";
import { useAuth } from "../Auth";
import { useToggle } from "@homewisedocs/client-utils/lib/useToggle";
import { WithAuthRedirects } from "../../containers/withAuthRedirects";
import { parseQueryStringParameters } from "@homewisedocs/client-utils/lib/routing";
import { useSmartMyOrdersRoute } from "../../utils/routing";

const useStyles = makeStyles(({ custom: { colors } }: Theme) =>
  createStyles({
    authMenuContainer: {
      // This balances the flex layout to center the nav items
      width: logoWidth,
    },
    authMenuWrapper: {
      position: "absolute",
      right: "2rem",
      transform: "translateY(-50%)",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    authMenuButton: {
      // The auth menu button uses a `<button>` tag for better semantics and
      // accessibility, but we don't want the content to look very button-y. We'll
      // have to reset a lot of button styles here.
      background: "none",
      border: "none",
      fontSize: "inherit",
      fontWeight: 400,
      fontFamily: "inherit",
      lineHeight: "inherit",
      letterSpacing: "inherit",
      // Most browsers add a border around content when it's focused via the
      // keyboard. This padding+margin doesn't affect the basic
      // appearance/layout of the content, but prevents that border from being
      // too snug.
      padding: "0.5rem",
      marginRight: "-0.5rem",
    },
    menuItemLink: {
      textDecoration: "none",
      color: colors.black,
    },
    loginBtn: {
      color: colors.white,
      fontSize: defaultNavbarFontSize,
    },
    signupBtn: {
      borderRadius: 4,
      width: "10rem",
      padding: "0.8rem 0",
      fontSize: defaultNavbarFontSize,
    },
    paperMenu: {
      marginTop: "0.4rem",
    },
  })
);

export const AuthMenu = () => {
  const location = useLocation();
  const classes = useStyles();

  const smartMyOrdersRoute = useSmartMyOrdersRoute();

  const welcomeRef = React.useRef(null);
  const [isDropdownOpen, toggleDropdownOpen] = useToggle(false);
  const { logout, authProps } = useAuth();

  const authMenuButtonId = "auth-menu-button";
  const authMenuId = "auth-menu";

  if (authProps.isAuthenticated) {
    return (
      <div className={classes.authMenuContainer}>
        <button
          id={authMenuButtonId}
          aria-controls={isDropdownOpen ? authMenuId : undefined}
          aria-haspopup="true"
          aria-expanded={isDropdownOpen ? "true" : undefined}
          ref={welcomeRef}
          className={classNames(
            classes.authMenuWrapper,
            classes.authMenuButton
          )}
          onClick={toggleDropdownOpen}
        >
          <WelcomeMessage
            username={authProps.user.username}
            orgName={authProps.user.orgName}
            orgType={authProps.user.orgType}
          />
          <UserAvatar
            userFirstName={authProps.user.firstName}
            userLastName={authProps.user.lastName}
          />
        </button>
        <Menu
          id={authMenuId}
          data-test={AuthMenuDataTest.dropdownMenu}
          open={isDropdownOpen}
          onClose={toggleDropdownOpen}
          MenuListProps={{
            "aria-labelledby": authMenuButtonId,
          }}
          anchorEl={welcomeRef.current}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          // getContentAnchorEl conflicts with anchorOrigin, so it needs to be disabled
          getContentAnchorEl={undefined}
          classes={{ paper: classes.paperMenu }}
        >
          <MenuItem
            onClick={toggleDropdownOpen}
            className={classes.menuItemLink}
            component={Link}
            {...({
              to: MY_ACCOUNT,
            } as any)}
          >
            My Account
          </MenuItem>
          <MenuItem
            onClick={toggleDropdownOpen}
            className={classes.menuItemLink}
            component={Link}
            {...({
              to: smartMyOrdersRoute,
            } as any)}
          >
            My Orders
          </MenuItem>
          <MenuItem
            onClick={() => {
              toggleDropdownOpen();
              logout();
            }}
            className={classes.menuItemLink}
          >
            Log Out
          </MenuItem>
        </Menu>
      </div>
    );
  }

  // If there's already a returnUrl query param at the current
  // location (like if the user's on the login page) when the user clicks the
  // Log In or Sign Up buttons, re-use that rather than setting the returnUrl
  // to the current page - if there's already a returnUrl, that probably means
  // we're on the login or registration pages and there's not much point in
  // returning to them.
  const returnUrl =
    parseQueryStringParameters(location.search).returnUrl || undefined;

  return (
    <WithAuthRedirects>
      {({ redirectToLoginPage, redirectToSignUpPage }) => (
        <div className={classes.authMenuContainer}>
          <div className={classes.authMenuWrapper}>
            <Button
              variant="text"
              onClick={() => {
                redirectToLoginPage({ returnUrl });
              }}
              className={classes.loginBtn}
            >
              Log In
            </Button>
            <Button
              secondary
              onClick={() => {
                redirectToSignUpPage({ returnUrl });
              }}
              className={classes.signupBtn}
            >
              Sign Up
            </Button>
          </div>
        </div>
      )}
    </WithAuthRedirects>
  );
};
