import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";
import { H1, H4, HeaderColor } from "@homewisedocs/components/lib/Header";
import classNames from "classnames";
import { useResponsive } from "@homewisedocs/components/lib/Responsive";
import { SolutionsMenuDataTest } from "./DataTest";

const styles = ({ custom: { colors } }: Theme) =>
  createStyles({
    desktopWrapper: {
      alignSelf: "center",
      padding: "0 2rem",
      boxSizing: "border-box",
      width: "43%",
    },
    mobileWrapper: {
      maxWidth: "34rem",
      width: "100%",
      margin: "0 auto 4rem",
      textAlign: "center",
      padding: "0 1rem",
    },
    subHeader: {
      fontSize: "1.6rem",
    },
    subHeaderDesktop: {
      marginBottom: "2rem",
    },
    subHeaderMobile: {
      marginBottom: "1rem",
    },
    mainHeaderDesktop: {
      whiteSpace: "nowrap",
      marginBottom: "5rem",
    },
    mainHeaderMobile: {
      marginBottom: "3rem",
    },
    subtext: {
      color: colors.fontMedium,
      fontSize: "1.8rem",
      lineHeight: 2,
    },
  });

export const OurSolutions = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => {
    const { mobile } = useResponsive();

    return (
      <div
        data-test={SolutionsMenuDataTest.ourSolutions}
        className={classNames({
          [classes.desktopWrapper]: !mobile,
          [classes.mobileWrapper]: mobile,
        })}
      >
        <H4
          className={classNames(classes.subHeader, {
            [classes.subHeaderDesktop]: !mobile,
            [classes.subHeaderMobile]: mobile,
          })}
          color={HeaderColor.secondaryDark}
        >
          Designed to meet your needs
        </H4>
        <H1
          color={HeaderColor.primary}
          className={classNames({
            [classes.mainHeaderDesktop]: !mobile,
            [classes.mainHeaderMobile]: mobile,
          })}
        >
          Our Solutions
        </H1>
        <p className={classes.subtext}>
          Review our application by user role or by stakeholder industries
        </p>
      </div>
    );
  }
);
