import { Action, ActionType } from "../actions";
import { enforceNonNullable } from "@homewisedocs/common/lib/utils/enforceNonNullable";
import { OrderAttachmentDocumentType } from "../__generated__/gql";

export type InitialState = Readonly<{
  selectedDocument: undefined;
  selectedDocumentType: undefined;
}>;

export type SelectedDocument = Readonly<{
  selectedDocument: File;
  selectedDocumentType: OrderAttachmentDocumentType;
}>;

export type SelectedDocumentType = Readonly<{
  selectedDocument?: File;
  selectedDocumentType: OrderAttachmentDocumentType;
}>;

export type State = InitialState | SelectedDocument | SelectedDocumentType;

const INITIAL_STATE: State = {
  selectedDocument: undefined,
  selectedDocumentType: undefined,
};

export const attachDocument = (
  state: State = INITIAL_STATE,
  action: Action
): State => {
  switch (action.type) {
    case ActionType.SET_SELECTED_DOCUMENT_TYPE:
      return {
        ...state,
        selectedDocument: state.selectedDocument,
        selectedDocumentType: action.payload,
      };
    case ActionType.SET_SELECTED_DOCUMENT:
      return {
        ...state,
        selectedDocument: action.payload,
        selectedDocumentType: enforceNonNullable(
          state.selectedDocumentType,
          "The selected document type cannot be undefined when uploading a file."
        ),
      };
    case ActionType.CLEAR_SELECTED_DOCUMENT:
      return {
        ...state,
        selectedDocument: undefined,
        selectedDocumentType: undefined,
      };
    default:
      return state;
  }
};
