import { Action, ActionType } from "../actions";
import { PaymentMethod } from "../__generated__/gql";

/**
 * Information about an existing order that the user is about to modify (e.g.
 * by adding a rush or submitting 3rd party payment).
 *
 * The `confirmationNumber` field may seem unnecessary. After all, if we know
 * an order's `uuid` and the order is in the Apollo cache, then the confirmation
 * number is probably in the cache. However, we reset the Apollo store after a
 * user submits 3rd party payment. As a result, the conf # would no longer be
 * accessible via the Apollo cache. In most browsers, this didn't actually
 * manifest as an issue. However, it caused an issue in Edge:
 * https://github.com/homewisedocs/requestor-app/issues/1424
 */
export interface ExistingOrderInfo {
  uuid: string;
  confirmationNumber: string;
}

type InitialState = Readonly<{
  paymentMethod: PaymentMethod;
  existingOrder: undefined;
}>;

type UpdatingOrderState = Readonly<{
  paymentMethod: PaymentMethod;
  existingOrder: ExistingOrderInfo;
}>;

export type State = InitialState | UpdatingOrderState;

const INITIAL_STATE: State = {
  // Set default payment type to credit card since that's the most common payment method.
  paymentMethod: PaymentMethod.CREDIT_CARD,
  existingOrder: undefined,
};

export const payment = (
  state: State = INITIAL_STATE,
  action: Action
): State => {
  switch (action.type) {
    case ActionType.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case ActionType.SET_EXISTING_ORDER_INFO:
      return {
        ...state,
        existingOrder: action.payload,
      };
    case ActionType.STOP_UPDATING_EXISTING_ORDER:
      return {
        ...state,
        existingOrder: undefined,
      };
    default:
      return state;
  }
};
