import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ custom: { colors }, breakpoints }) => {
  const smDown = breakpoints.down("sm");

  return createStyles({
    wrapper: {
      textAlign: "left",
      [smDown]: {
        textAlign: "center",
        marginBottom: "4rem",
      },
    },
    copyright: {
      color: colors.white,
      fontSize: "1.4rem",
      lineHeight: "2rem",
    },
  });
});

export const Copyright: React.FC = () => {
  const classes = useStyles();

  const currentYear = new Date().getFullYear();

  return (
    <div className={classes.wrapper}>
      <small className={classes.copyright}>
        Copyright &copy; 2008&ndash;{currentYear} HomeWiseDocs.com All rights
        reserved.
      </small>
    </div>
  );
};
