import Raven from "raven-js";
import { Environment } from "@homewisedocs/common/lib/env";
import { getReactAppEnv } from "@homewisedocs/client-utils/lib/env";

// ! This file should be ran as soon as possible, because it will log uncaught
// exceptions to Sentry. If we run any code before this and that code causes an
// uncaught exception, the exception won't get logged.
const ravenDsn = "https://28285e7bbc674cd68fe19775941ea9a2@sentry.io/1265790";
const releaseSha = process.env.REACT_APP_RELEASE_SHA;
try {
  Raven.config(ravenDsn, {
    environment: getReactAppEnv(),
    release: releaseSha,
  }).install();
} catch {
  Raven.config(ravenDsn, {
    environment: Environment.DEV,
    release: releaseSha,
  }).install();
}
