export const ROOT = "/";
export const HOMEPAGE = "/home";

// Auth
export const LOGIN = "/login";
export const buildLoginLink = (returnUrl?: string) =>
  returnUrl ? `${LOGIN}?returnUrl=${encodeURIComponent(returnUrl)}` : LOGIN;
export const FORGOT_USERNAME = "/forgot-username";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const SIGN_UP = "/sign-up";
export const buildSignUpLink = (returnUrl?: string) =>
  returnUrl ? `${SIGN_UP}?returnUrl=${encodeURIComponent(returnUrl)}` : SIGN_UP;

// Navbar
export const MY_ACCOUNT = "/my-account";
export const MY_ORDERS = "/my-orders";
export const SOLUTIONS = "/solutions";

export const SOLUTIONS_FOR_PROCESSORS_OPERATIONS = `${SOLUTIONS}/processors-and-operations`;
export const SOLUTIONS_FOR_OWNERS_EXECUTIVES = `${SOLUTIONS}/owners-and-executives`;
export const SOLUTIONS_FOR_CLOSING_AGENTS = `${SOLUTIONS}/closing-agents`;
export const SOLUTIONS_FOR_TECHNOLOGY_OFFICERS = `${SOLUTIONS}/technology-officers`;
export const SOLUTIONS_FOR_LENDERS = `${SOLUTIONS}/lenders`;
export const SOLUTIONS_FOR_REALTORS = `${SOLUTIONS}/realtors`;
export const SOLUTIONS_FOR_FINANCE_EXECUTIVES = `${SOLUTIONS}/finance-executives`;
export const SOLUTIONS_FOR_ONSITE_MANAGERS = `${SOLUTIONS}/onsite-managers`;

/**
 * Links to Zendesk Knowledge Base. These should be opened in a new tab
 * using `target="_blank"` and `rel="noopener noreferrer"`
 */
export const FAQS = "http://help.homewisedocshelpcenter.com/";
export const PRIVACY_STATEMENT =
  "https://help.homewisedocshelpcenter.com/s/topic/0TO4X000000QAXKWA4/policies";
export const USER_AGREEMENT =
  "https://www.homewisedocs.com/agreements/enduseragreement";

export const ORDER_STATUS = "/order-status";

type BuildOrderStatusPushParamsFn = (
  confirmationNumber: string | null
) => [path: string, locationState: { confirmationNumber: string | null }];

/**
 * The tuple returned by this function can be spread into `history.push` or
 * `history.replace` as parameters to send the user to the order status
 * page for the order corresponding to the given confirmation number.
 */
export const buildOrderStatusPushParams: BuildOrderStatusPushParamsFn =
  confirmationNumber => [ORDER_STATUS, { confirmationNumber }];

/**
 * Note: this app doesn't directly send users to this route. However, we have
 * an email that sends users to it.
 */
export const PAY_FOR_ORDER_STATUS = "/pay-for-order-status";

/**
 * The tuple returned by this function can be spread into `history.push` or
 * `history.replace` as parameters to send the user to the pay-for-order
 * page for the order corresponding to the given confirmation number.
 */
export const buildPayForOrderStatusPushParams: BuildOrderStatusPushParamsFn =
  confirmationNumber => [PAY_FOR_ORDER_STATUS, { confirmationNumber }];

// My Account
export const MY_ACCOUNT_EDIT_PROFILE = `${MY_ACCOUNT}/edit-profile`;
export const MY_ACCOUNT_CHANGE_PASSWORD = `${MY_ACCOUNT}/change-password`;
export const MY_ACCOUNT_USERS = `${MY_ACCOUNT}/users`;
export const MY_ACCOUNT_USERS_CREATE_USER = `${MY_ACCOUNT_USERS}/create`;
export const MY_ACCOUNT_USERS_VIEW_PROFILE_WITH_UUID = `${MY_ACCOUNT_USERS}/view/:uuid`;
export const buildMyAccountsUsersViewProfileLink = (id: string) =>
  MY_ACCOUNT_USERS_VIEW_PROFILE_WITH_UUID.replace(":uuid", id);

// Contact Sales
export const CONTACT_SALES = "/contact-sales";

// Search Results
export const SEARCH_RESULTS_BASE = "/search-results";
export const SEARCH_RESULTS_ASSOCIATION = `${SEARCH_RESULTS_BASE}/hoa`;
export const SEARCH_RESULTS_ADDRESS = `${SEARCH_RESULTS_BASE}/address`;
export type SearchResultsPageRouteParams = {
  uuid: string;
};
export const buildSearchResultsAssociationLink = ({
  uuid,
}: SearchResultsPageRouteParams) => `${SEARCH_RESULTS_ASSOCIATION}/${uuid}`;
export const buildSearchResultsAddressLink = ({
  uuid,
}: SearchResultsPageRouteParams) => `${SEARCH_RESULTS_ADDRESS}/${uuid}`;
export const SEARCH_RESULTS_ASSOCIATION_WITH_ID = `${SEARCH_RESULTS_ASSOCIATION}/:uuid`;
export const SEARCH_RESULTS_ADDRESS_WITH_ID = `${SEARCH_RESULTS_ADDRESS}/:uuid`;

// Resale Transaction
export const RESALE_TRANSACTION = "/resale-transaction";

// Select Items
export const SELECT_ITEMS = "/select-your-items";

// Select Master Hoa Items
export const SELECT_MASTER_HOA_ITEMS = "/select-your-master-hoa-items";

// Lender-Specific Questionnaire
export const LSQ = "/lender-forms";

// Review Order
export const REVIEW_ORDER = "/review-order";

// Transaction Details
export const TRANSACTION_DETAILS = "/transaction-details";

// Delivery
export const DELIVERY = "/delivery";

// Pay Method
export const PAYMENT_METHOD = "/payment-method";
export const THIRD_PARTY_PAYMENT = "/third-party-payment";
export const ADDED_RUSH_PAYMENT = "/added-rush-payment";
export const UPDATE_REQUEST_PAYMENT = "/update-request-payment";

// Confirm Order
export const CONFIRM_ORDER = "/confirm-order";
