import { Action, ActionType } from "../actions";
import { Address } from "../typings/address";
import { CurrentOwnerAddressVerificationStatus } from "../__generated__/gql";

// Model the search results state using a union of the valid states:
// A) initial state (no search results set yet)
// B) state after searching by HOA
// C) state after searching for a particular unit in an HOA

export enum SearchBy {
  HOA = "[SearchBy] HOA",
  HOA_UNIT = "[SearchBy] HOA unit",
}

type InitialState = Readonly<{
  searchBy: undefined;
  hoaUUID?: undefined;
  hoaUnitUUID?: undefined;
}>;

type SearchByHoaState = Readonly<
  {
    searchBy: SearchBy.HOA;
    hoaUUID: string;
    hoaUnitUUID?: undefined;
  } & (
    | {
        address?: undefined;
        addressVerificationStatus?: undefined;
      }
    | {
        address: Address;
        addressVerificationStatus?: CurrentOwnerAddressVerificationStatus;
      }
  )
>;

type SearchByHoaUnitState = Readonly<{
  searchBy: SearchBy.HOA_UNIT;
  hoaUUID: string;
  hoaUnitUUID: string;
  address: Address;
  addressVerificationStatus: CurrentOwnerAddressVerificationStatus.VERIFIED_HOMEWISE;
}>;

export type State = InitialState | SearchByHoaState | SearchByHoaUnitState;

const INITIAL_STATE: InitialState = {
  searchBy: undefined,
  hoaUUID: undefined,
  hoaUnitUUID: undefined,
};

export const searchResults = (
  state: State = INITIAL_STATE,
  action: Action
): State => {
  switch (action.type) {
    case ActionType.SET_SEARCH_RESULTS:
      const { payload } = action;
      if (payload.searchBy === SearchBy.HOA) {
        return {
          ...payload,
          searchBy: SearchBy.HOA,
          hoaUnitUUID: undefined,
        };
      }
      if (payload.searchBy === SearchBy.HOA_UNIT) {
        return {
          ...payload,
          searchBy: SearchBy.HOA_UNIT,
        };
      }
      return {
        searchBy: undefined,
        hoaUUID: undefined,
        hoaUnitUUID: undefined,
      };
    case ActionType.SET_HOA_UUID:
      return {
        ...state,
        searchBy: SearchBy.HOA,
        hoaUUID: action.payload,
        hoaUnitUUID: undefined,
      };
    case ActionType.SET_HOA_PROPERTY_ADDRESS:
      if (state.searchBy === SearchBy.HOA) {
        return {
          ...action.payload,
          searchBy: SearchBy.HOA,
          hoaUUID: state.hoaUUID,
          hoaUnitUUID: undefined,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
