import { UiTransactionType } from "../typings/transactionType";
import { TransactionType as ApiTransactionType } from "../../src/__generated__/gql";

type UiResaleTransaction =
  | UiTransactionType.BuySell
  | UiTransactionType.BuySellBehalf;

export const isResaleTransaction = (
  transactionType?: UiTransactionType
): transactionType is UiResaleTransaction =>
  transactionType === UiTransactionType.BuySell ||
  transactionType === UiTransactionType.BuySellBehalf;

type UiRefiTransaction =
  | UiTransactionType.Refinance
  | UiTransactionType.RefinanceBehalf;

export const isRefiTransaction = (
  transactionType?: UiTransactionType
): transactionType is UiRefiTransaction =>
  transactionType === UiTransactionType.Refinance ||
  transactionType === UiTransactionType.RefinanceBehalf;

export const apiTransactionType = (
  transactionType?: UiTransactionType
): ApiTransactionType | undefined => {
  if (isResaleTransaction(transactionType)) {
    return ApiTransactionType.RESALE;
  } else if (isRefiTransaction(transactionType)) {
    return ApiTransactionType.REFINANCE;
  } else if (transactionType === UiTransactionType.Other) {
    return ApiTransactionType.OTHER;
  }
  return undefined;
};
