import { LinkItems, Props as LinkItemsProps } from "./shared/LinkItems";
import { SolutionsMenuDataTest } from "./DataTest";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import {
  SOLUTIONS_FOR_CLOSING_AGENTS,
  SOLUTIONS_FOR_LENDERS,
  SOLUTIONS_FOR_REALTORS,
} from "../../constants/routes";

export const industriesLinkItems: LinkItemsProps["linkItems"] = [
  {
    label: "Realtors",
    path: SOLUTIONS_FOR_REALTORS,
  },
  {
    label: "Lenders",
    path: SOLUTIONS_FOR_LENDERS,
  },
  {
    label: "Closing Agents, Title & Escrow Companies",
    path: SOLUTIONS_FOR_CLOSING_AGENTS,
  },
];

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    wrapper: {
      [breakpoints.up("md")]: {
        boxSizing: "border-box",
        width: "50%",
      },
      [breakpoints.down("sm")]: {
        paddingTop: "0",
      },
    },
  })
);

export const Industries = ({
  onLinkItemClick,
}: Pick<LinkItemsProps, "onLinkItemClick">) => {
  const classes = useStyles();

  return (
    <LinkItems
      data-test={SolutionsMenuDataTest.industries}
      header="Industries"
      linkItems={industriesLinkItems}
      onLinkItemClick={onLinkItemClick}
      className={classes.wrapper}
    />
  );
};
