import * as React from "react";
import * as Raven from "raven-js";
import { Mutation } from "@apollo/client/react/components";
import { reduxForm } from "redux-form";
import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";
import { Modal } from "@homewisedocs/components/lib/Modal";
import { WithErrorNotification } from "@homewisedocs/components/lib/WithErrorNotification";
import { enforceNonNullable } from "@homewisedocs/common/lib/utils/enforceNonNullable";
import {
  SessionExtensionManager,
  SessionExtensionManagerRenderProps,
  SessionExtensionModalContent,
} from "@homewisedocs/components/lib/SessionExtension";
import { useAuth } from "../Auth";
import { FormValues } from "../../utils/reduxForm";
import { FormTextField } from "../FormTextField";
import {
  LOGIN_MUTATION,
  LoginErrorContent,
} from "../../pageContent/Login/shared";
import { LoginMutation, LoginMutationVariables } from "../../__generated__/gql";
import {
  trackRecaptchaAction,
  RecaptchaAction,
} from "../../monitoring/recaptcha";
import { eventTrackers } from "../../monitoring/events";
import { extendSessionFormName } from "./formMetadata";

export const SessionExtender = () => {
  const { authProps, onAuthenticated } = useAuth();

  if (!authProps.isAuthenticated) {
    return null;
  }

  return (
    <SessionExtensionManager
      effectiveTokenExpirationDate={authProps.effectiveTokenExpirationDate}
    >
      {({ showSessionExtensionOffer, onDismiss, onSessionExtended }) => (
        <SessionExtensionModal
          showSessionExtensionOffer={showSessionExtensionOffer}
          onDismiss={onDismiss}
          onSessionExtended={() => {
            onSessionExtended();
            onAuthenticated();
          }}
          username={authProps.user.username}
          expirationDate={authProps.effectiveTokenExpirationDate}
        />
      )}
    </SessionExtensionManager>
  );
};

interface SessionExtensionModalProps
  extends SessionExtensionManagerRenderProps {
  expirationDate: Date;
  username: string;
}

type ExtendSessionFormFieldNames = "password";

const extendSessionFieldNames: FormFieldNameMap<ExtendSessionFormFieldNames> = {
  password: "password",
};

type ExtendSessionFormValues = FormValues<ExtendSessionFormFieldNames>;

const withReduxForm = reduxForm<
  ExtendSessionFormValues,
  SessionExtensionModalProps
>({
  form: extendSessionFormName,
  destroyOnUnmount: true,
});

const SessionExtensionModal = withReduxForm(
  ({
    showSessionExtensionOffer,
    expirationDate,
    username,
    onDismiss,
    onSessionExtended,
    handleSubmit,
    invalid,
    submitFailed,
    reset,
  }) => {
    React.useEffect(() => {
      if (showSessionExtensionOffer) {
        eventTrackers.trackExtendSessionOffer();
      }
    }, [showSessionExtensionOffer]);

    const [recaptchaPending, setRecaptchaPending] = React.useState(false);

    return (
      <Modal
        open={showSessionExtensionOffer}
        soft={false}
        onClose={() => {}}
        displayCloseButton={false}
      >
        <WithErrorNotification>
          {({ setErrorNotification }) => (
            <Mutation<LoginMutation, LoginMutationVariables>
              mutation={LOGIN_MUTATION}
              onCompleted={data => {
                if (data.login.success) {
                  onSessionExtended();
                  // Clear password field
                  reset();
                } else if (data.login.error) {
                  setErrorNotification(
                    <LoginErrorContent
                      error={data.login.error}
                      isSessionExtensionAttempt
                    />
                  );
                }
              }}
            >
              {(sendLoginMutation, { loading: loginMutationPending }) => (
                <form
                  noValidate
                  onSubmit={handleSubmit(async formValues => {
                    const password = enforceNonNullable(
                      formValues.password,
                      "Password is required on session extension form"
                    );

                    let recaptchaToken: string | null = null;
                    try {
                      setRecaptchaPending(true);
                      recaptchaToken = await trackRecaptchaAction(
                        RecaptchaAction.ATTEMPT_EXTEND_SESSION
                      );
                    } catch (e) {
                      Raven.captureException(e);
                    } finally {
                      setRecaptchaPending(false);
                    }

                    await sendLoginMutation({
                      variables: {
                        username,
                        password,
                        recaptchaToken,
                      },
                    });
                  })}
                >
                  <SessionExtensionModalContent
                    expirationDate={expirationDate}
                    freezeCountdown={!showSessionExtensionOffer}
                    extensionPending={recaptchaPending || loginMutationPending}
                    submitDisabled={submitFailed && invalid}
                    passwordInput={
                      <FormTextField
                        // Since this modal can appear on any page that logged
                        // in users can visit, we wouldn't want to use a generic
                        // value like "password" here. It could wind up being
                        // rendered on a page that already has an input with
                        // that id. We can add a prefix to ensure uniqueness.
                        id="sessionExtender-password"
                        name={extendSessionFieldNames.password}
                        label="Password"
                        type="password"
                        // don't validate this field for password
                        // requirements; long-standing users could have
                        // passwords that were set before those
                        // requirements were in place and validating would
                        // leave them unable to log in
                        required
                      />
                    }
                    onDismiss={onDismiss}
                  />
                </form>
              )}
            </Mutation>
          )}
        </WithErrorNotification>
      </Modal>
    );
  }
);
