import { LinkItems, Props as LinkItemsProps } from "./shared/LinkItems";
import { SolutionsMenuDataTest } from "./DataTest";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import {
  SOLUTIONS_FOR_PROCESSORS_OPERATIONS,
  SOLUTIONS_FOR_OWNERS_EXECUTIVES,
  SOLUTIONS_FOR_TECHNOLOGY_OFFICERS,
  SOLUTIONS_FOR_FINANCE_EXECUTIVES,
} from "../../constants/routes";

export const roleLinkItems: LinkItemsProps["linkItems"] = [
  {
    label: "Community Manager Owners & Executives",
    path: SOLUTIONS_FOR_OWNERS_EXECUTIVES,
  },
  {
    label: "Processors & Operations",
    path: SOLUTIONS_FOR_PROCESSORS_OPERATIONS,
  },
  {
    label: "Technology Officers",
    path: SOLUTIONS_FOR_TECHNOLOGY_OFFICERS,
  },
  {
    label: "Finance Executives",
    path: SOLUTIONS_FOR_FINANCE_EXECUTIVES,
  },
];

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    wrapper: {
      [breakpoints.up("md")]: {
        boxSizing: "border-box",
        width: "50%",
      },
    },
  })
);

export const Roles = ({
  onLinkItemClick,
}: Pick<LinkItemsProps, "onLinkItemClick">) => {
  const classes = useStyles();

  return (
    <LinkItems
      data-test={SolutionsMenuDataTest.roles}
      header="Roles"
      linkItems={roleLinkItems}
      onLinkItemClick={onLinkItemClick}
      className={classes.wrapper}
    />
  );
};
