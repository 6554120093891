import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { DataTest } from "./dataTest";
import { Copyright } from "./Copyright";
import { BBBIcon } from "./BBBIcon";
import { FooterLogo } from "./FooterLogo";
import { LinkItems, Props as LinkItemsProps } from "./LinkItems";
import {
  SOLUTIONS_FOR_CLOSING_AGENTS,
  SOLUTIONS_FOR_PROCESSORS_OPERATIONS,
  SOLUTIONS_FOR_OWNERS_EXECUTIVES,
  SOLUTIONS_FOR_TECHNOLOGY_OFFICERS,
  SOLUTIONS_FOR_FINANCE_EXECUTIVES,
  SOLUTIONS_FOR_REALTORS,
  SOLUTIONS_FOR_LENDERS,
  PRIVACY_STATEMENT,
  USER_AGREEMENT,
} from "../../constants/routes";
import { useSmartMyOrdersRoute } from "../../utils/routing";

type Links = LinkItemsProps["linkItems"];

export const solutionsFooterLinks: Links = [
  {
    label: "Community Manager Owners & Executives",
    path: SOLUTIONS_FOR_OWNERS_EXECUTIVES,
  },
  {
    label: "Processors & Operations",
    path: SOLUTIONS_FOR_PROCESSORS_OPERATIONS,
  },
  {
    label: "Technology Officers",
    path: SOLUTIONS_FOR_TECHNOLOGY_OFFICERS,
  },
  {
    label: "Finance Executives",
    path: SOLUTIONS_FOR_FINANCE_EXECUTIVES,
  },
  {
    label: "Realtors",
    path: SOLUTIONS_FOR_REALTORS,
  },
  {
    label: "Lenders",
    path: SOLUTIONS_FOR_LENDERS,
  },
  {
    label: "Closing Agents, Title & Escrow Companies",
    path: SOLUTIONS_FOR_CLOSING_AGENTS,
  },
];

export const faqsFooterLinks: Links = [
  {
    label: "Getting Started",
    path: "https://help.homewisedocshelpcenter.com/s/topic/0TO4X000000QAWHWA4/getting-started",
    openNewTab: true,
  },
  {
    label: "Account Management",
    path: "https://help.homewisedocshelpcenter.com/s/topic/0TO4X000000QAWMWA4/account-management",
    openNewTab: true,
  },
  {
    label: "Payments",
    path: "https://help.homewisedocshelpcenter.com/s/topic/0TO4X000000QAWRWA4/payments",
    openNewTab: true,
  },
  {
    label: "Lender Questionnaires",
    path: "https://help.homewisedocshelpcenter.com/s/topic/0TO4X000000QAWWWA4/lender-questionnaires",
    openNewTab: true,
  },
  {
    label: "Terminology",
    path: "https://help.homewisedocshelpcenter.com/s/topic/0TO4X000000QAWXWA4/terminology",
    openNewTab: true,
  },
  {
    label: "Contact Us",
    path: "https://help.homewisedocshelpcenter.com/s/topic/0TO4X000000QAWbWAO/contact-homewisedocs",
    openNewTab: true,
  },
];

export const policiesAndPrivacyLinks: Links = [
  {
    label: "Privacy Policy",
    path: PRIVACY_STATEMENT,
    openNewTab: true,
  },
  {
    label: "User Agreement",
    path: USER_AGREEMENT,
    openNewTab: true,
  },
];

const useStyles = makeStyles(({ custom: { colors }, breakpoints }) => {
  const smDown = breakpoints.down("sm");
  const mdUp = breakpoints.up("md");

  return createStyles({
    footerWrapper: {
      background: colors.primary,
      padding: "4rem 5rem 2.5rem",
      // Prevents an IE 11 issue where the bottom of the footer would get cut
      // off, leaving blank space at the bottom of the page. That issue may only
      // occur when using Windows 7.
      flex: "1 0 auto",
    },
    flexWrapper: {
      display: "flex",
    },
    topSection: {
      [mdUp]: {
        marginBottom: "2rem",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "row",
      },
      [smDown]: {
        alignItems: "center",
        flexDirection: "column",
      },
    },
    logosWrapper: {
      marginRight: "5rem",
      [smDown]: {
        marginRight: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
  });
});

export const Footer: React.FC<{ compact: boolean }> = ({ compact }) => {
  const classes = useStyles();

  return (
    <footer data-test={DataTest.footer} className={classes.footerWrapper}>
      <div className={classNames(classes.flexWrapper, classes.topSection)}>
        <div className={classes.logosWrapper}>
          <FooterLogo />
          <BBBIcon />
        </div>
        <FooterMenuItems compact={compact} />
      </div>
      <Copyright />
    </footer>
  );
};

const useMenuItemsStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    menuItems: {
      display: "flex",
      justifyContent: "space-between",
      [breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  })
);

const FooterMenuItems: React.FC<{ compact: boolean }> = ({ compact }) => {
  const classes = useMenuItemsStyles();
  const smartMyOrdersRoute = useSmartMyOrdersRoute();

  return (
    <div className={classes.menuItems}>
      <LinkItems
        data-test={DataTest.myOrders}
        header="My Orders"
        linkItems={[
          {
            label: "View Orders",
            path: smartMyOrdersRoute,
            linkProps: {
              // Add 'nofollow' so search engines don't index the 'My Orders'
              // page, which will (perhaps confusingly) send anonymous users to
              // the login page.
              rel: "nofollow",
            },
          },
        ]}
      />
      {!compact && (
        <LinkItems
          data-test={DataTest.solutions}
          header="Solutions"
          linkItems={solutionsFooterLinks}
        />
      )}
      <LinkItems
        data-test={DataTest.faqs}
        header="FAQs"
        linkItems={faqsFooterLinks}
      />
      <LinkItems
        data-test={DataTest.policiesAndPrivacy}
        header="Policies & Privacy"
        linkItems={policiesAndPrivacyLinks}
      />
    </div>
  );
};
