import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";
import { DataTest } from "./dataTest";
import { FAQS, MY_ACCOUNT } from "../../constants/routes";
import { UserAvatar } from "@homewisedocs/components/lib/GenericNavbar/UserAvatar";
import { WelcomeMessage } from "@homewisedocs/components/lib/GenericNavbar/WelcomeMessage";
import { useAuth } from "../Auth";
import { WithAuthRedirects } from "../../containers/withAuthRedirects";
import { Solutions } from "./Solutions";
import { useSmartMyOrdersRoute } from "../../utils/routing";

const useStyles = makeStyles(({ custom: { colors } }) =>
  createStyles({
    root: {
      paddingTop: "4rem",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    menuItem: {
      fontSize: "2rem",
      textDecoration: "none",
      cursor: "pointer",
      color: "white",
      padding: "1rem",
      marginBottom: "1.5rem",
      "&:hover": {
        color: colors.secondary,
      },
    },
    authContainer: {
      marginTop: "6rem",
    },
    nameAndAvatarWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "6rem",
    },
  })
);

type MenuItemProps = { className?: string; children: React.ReactNode } & (
  | ({
      isLink: true;
    } & LinkProps)
  | {
      isLink: false;
      onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    }
);

// This component is rendered inside a <Slide>, so it needs to forward its ref
export const MenuItem = React.forwardRef<any, MenuItemProps>(
  ({ className, ...props }, ref) => {
    const classes = useStyles();
    const combinedClassName = classNames(classes.menuItem, className);
    if (props.isLink) {
      const { isLink: unusedIsLink1, ...linkProps } = props; // Don't spread `isLink` down through to the DOM
      return <Link {...linkProps} className={combinedClassName} ref={ref} />;
    }

    const { isLink: unusedIsLink2, ...divProps } = props;
    return <div {...divProps} className={combinedClassName} ref={ref} />;
  }
);

const LogInMenuItem = React.forwardRef<unknown, { onClick: () => void }>(
  ({ onClick }, ref) => {
    const classes = useStyles();
    return (
      <WithAuthRedirects>
        {({ redirectToLoginPage }) => (
          <MenuItem
            isLink={false}
            onClick={() => {
              onClick();
              redirectToLoginPage();
            }}
            className={classes.authContainer}
            ref={ref}
          >
            Log In
          </MenuItem>
        )}
      </WithAuthRedirects>
    );
  }
);

export interface Props {
  /**
   * Callback to close the overlay.
   */
  onClose: () => void;

  /**
   * CSS className
   */
  className?: string;
}

// Match the transition duration of the hamburger menu
const TRANSITION_DURATION_MS = 300;

export const VerticalMenu = ({ onClose, className, ...props }: Props) => {
  const classes = useStyles();
  const [solutionsOpen, setSolutionsOpen] = React.useState(false);
  const { logout, authProps } = useAuth();
  const smartMyOrdersRoute = useSmartMyOrdersRoute();

  return (
    <nav
      data-test={DataTest.VerticalMenu}
      className={classNames(classes.root, className)}
      {...props}
    >
      <Solutions
        open={solutionsOpen}
        onClose={() => setSolutionsOpen(false)}
        closeHamburgerMenu={onClose}
      />
      {authProps.isAuthenticated && (
        <>
          <div className={classes.nameAndAvatarWrapper}>
            <Slide in={true} direction="right" timeout={TRANSITION_DURATION_MS}>
              <div>
                {/* Note: we need the div wrapped around this for the animation to work */}
                <WelcomeMessage
                  username={authProps.user.username}
                  orgName={authProps.user.orgName}
                  orgType={authProps.user.orgType}
                />
              </div>
            </Slide>

            <Slide in={true} direction="left" timeout={TRANSITION_DURATION_MS}>
              <div>
                <UserAvatar
                  userFirstName={authProps.user.firstName}
                  userLastName={authProps.user.lastName}
                />
              </div>
            </Slide>
          </div>
          <MenuItem isLink to={MY_ACCOUNT} onClick={onClose}>
            My Account
          </MenuItem>
        </>
      )}
      <MenuItem
        isLink
        to={smartMyOrdersRoute}
        onClick={onClose}
        // Add 'nofollow' so search engines don't index the 'My Orders' page,
        // which will (perhaps confusingly) send anonymous users to the login
        // page.
        rel="nofollow"
      >
        My Orders
      </MenuItem>
      <MenuItem isLink={false} onClick={() => setSolutionsOpen(true)}>
        Solutions
      </MenuItem>
      {/* Because we are using an external url we need to use an 'a' tag
    since react-router doesn't handle external links with <Link>.
    Reference: https://github.com/ReactTraining/react-router/issues/1147 */}
      <a
        className={classes.menuItem}
        href={FAQS}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClose}
      >
        FAQs
      </a>
      {!authProps.isAuthenticated && (
        <WithAuthRedirects>
          {({ redirectToSignUpPage }) => (
            <MenuItem
              isLink={false}
              onClick={() => {
                onClose();
                redirectToSignUpPage();
              }}
            >
              Sign Up
            </MenuItem>
          )}
        </WithAuthRedirects>
      )}
      <Slide in={true} direction="up" timeout={TRANSITION_DURATION_MS}>
        {authProps.isAuthenticated ? (
          <MenuItem
            isLink={false}
            onClick={() => {
              onClose();
              logout();
            }}
            className={classes.authContainer}
          >
            Log Out
          </MenuItem>
        ) : (
          <LogInMenuItem onClick={onClose} />
        )}
      </Slide>
    </nav>
  );
};
