import { FormValues } from "../../utils/reduxForm";
import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";

// Text notification section
type TextNotificationFormFieldNames = "textNotificationPhoneNumber";
export const TextNotificationFormNames: FormFieldNameMap<TextNotificationFormFieldNames> =
  {
    textNotificationPhoneNumber: "textNotificationPhoneNumber",
  };
type TextNotificationFormValues = FormValues<TextNotificationFormFieldNames>;

// Share Your Order section
// Currently we don't allow the requestor to share their order with more than
// 10 email addresses. So we can enumerate all 10 email fields here.
type EmailFormFieldNames =
  | "email0"
  | "email1"
  | "email2"
  | "email3"
  | "email4"
  | "email5"
  | "email6"
  | "email7"
  | "email8"
  | "email9";

export const emailFormSectionName = "shareOrderEmails";

interface EmailFormValues {
  [emailFormSectionName]: FormValues<EmailFormFieldNames>;
}

// Hard copy section
type HardCopyFormFieldNames =
  | "hardCopyShippingMethod"
  | "hardCopyName"
  | "hardCopyAddress"
  | "hardCopyCity"
  | "hardCopyUnit"
  | "hardCopyState"
  | "hardCopyZip"
  | "hardCopyCountry";

export const HardCopyFormNames: FormFieldNameMap<HardCopyFormFieldNames> = {
  hardCopyShippingMethod: "hardCopyShippingMethod",
  hardCopyName: "hardCopyName",
  hardCopyAddress: "hardCopyAddress",
  hardCopyCity: "hardCopyCity",
  hardCopyUnit: "hardCopyUnit",
  hardCopyState: "hardCopyState",
  hardCopyZip: "hardCopyZip",
  hardCopyCountry: "hardCopyCountry",
};

export type HardCopyFormValues = FormValues<HardCopyFormFieldNames>;

// Processing options section
type FormFieldNames = "estimatedClosingDate" | "processingOption";

export const ProcessingOptionsFormFieldMap: FormFieldNameMap<FormFieldNames> = {
  estimatedClosingDate: "estimatedClosingDate",
  processingOption: "processingOption",
};

export const STANDARD_PROCESSING_RADIO_VALUE = "standard processing";

export const estimatedClosingDateFormFieldName =
  ProcessingOptionsFormFieldMap.estimatedClosingDate;
export const processingOptionFormFieldName =
  ProcessingOptionsFormFieldMap.processingOption;

export type ProcessingOptionsFormValues = FormValues<FormFieldNames>;

export interface DeliveryFormValues
  extends EmailFormValues,
    TextNotificationFormValues,
    HardCopyFormValues,
    ProcessingOptionsFormValues {}

export const deliveryFormName = "Delivery";
