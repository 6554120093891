import * as React from "react";
import Raven from "raven-js";
import { useChunkFetchingErrorHandler } from "@homewisedocs/client-utils/lib/chunkFetching";
import {
  GenericErrorBoundary,
  ErrorBoundaryVariant,
} from "@homewisedocs/components/lib/GenericErrorBoundary";
import { GenericErrorPage } from "@homewisedocs/components/lib/GenericErrorPage";
import { HOMEPAGE } from "../../constants/routes";
import { Navbar } from "../../components/Navbar";
import LoadingPage from "../../pageContent/LoadingPage";

interface ErrorBoundaryProps {
  variant: ErrorBoundaryVariant;
}

// Re-export variant enum for convenience
export { ErrorBoundaryVariant };

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  variant,
}) => {
  const {
    pageReloadingAfterChunkFetchingError,
    handlePossibleChunkFetchingError,
  } = useChunkFetchingErrorHandler();

  return (
    <GenericErrorBoundary
      variant={variant}
      onError={({ error, info }) => {
        Raven.captureException(error, {
          extra: {
            ...info,
            reactErrorBoundary: true,
            errorBoundaryVariant: variant,
          },
        });

        handlePossibleChunkFetchingError(error);
      }}
      renderCriticalErrorContent={({ error }) => {
        if (pageReloadingAfterChunkFetchingError) {
          return (
            <>
              <Navbar />
              <LoadingPage />
            </>
          );
        }

        return <GenericErrorPage error={error} homeRoute={HOMEPAGE} />;
      }}
    >
      {children}
    </GenericErrorBoundary>
  );
};
