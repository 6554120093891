import * as React from "react";
import { gql } from "@apollo/client";
import { LoginMutation } from "../../__generated__/gql";
import { LoginMutationErrorCode } from "../../__generated__/gql";
import { UnreachableCaseError } from "@homewisedocs/common/lib/errors";

export const LOGIN_MUTATION = gql`
  mutation LoginMutation(
    $username: String!
    $password: String!
    $recaptchaToken: String
  ) {
    login(
      username: $username
      password: $password
      clientAppType: REQUESTOR_APP
      recaptchaToken: $recaptchaToken
    ) {
      success
      error {
        message
        code
      }
    }
  }
`;

export const LoginErrorContent: React.FC<{
  error: NonNullable<LoginMutation["login"]["error"]>;
  isSessionExtensionAttempt?: boolean;
}> = ({ error, isSessionExtensionAttempt = false }) => {
  switch (error.code) {
    case LoginMutationErrorCode.INVALID_CREDENTIALS:
      return (
        <>{isSessionExtensionAttempt ? "Invalid password." : error.message}</>
      );
    case LoginMutationErrorCode.MGMT_ATTEMPT_ON_REQ_SITE:
      return (
        <p>
          You are attempting to log into a Community Management account. To log
          in, please visit the manager site by clicking this link:{" "}
          <a href="https://manager.homewisedocs.com">
            https://manager.homewisedocs.com
          </a>
        </p>
      );
    case LoginMutationErrorCode.ADMIN_ATTEMPT_ON_REQ_SITE:
      return (
        <p>
          You are attempting to log into a HomeWiseDocs Admin account. To log
          in, please visit the manager site by clicking this link:{" "}
          <a href="https://manager.homewisedocs.com">
            https://manager.homewisedocs.com
          </a>
        </p>
      );
    case LoginMutationErrorCode.REQ_ATTEMPT_ON_MGMT_SITE:
      throw new Error(
        `Expected a valid LoginMutationErrorCode, but received: ${LoginMutationErrorCode.REQ_ATTEMPT_ON_MGMT_SITE}
        This error code should never occur on the requestor site.`
      );
    default:
      throw new UnreachableCaseError(
        error.code,
        `LoginErrorContent: expected a valid error code. Received: ${error.code}`
      );
  }
};
