import * as React from "react";
import { connect } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { Actions } from "../../actions";

interface OwnProps {
  isAuthenticated: boolean;
}

interface DispatchProps {
  resetReduxStore: () => void;
}

const withResetReduxStore = connect<{}, DispatchProps, OwnProps>(null, {
  resetReduxStore: Actions.resetStore,
});

export const ResetStateOnLogout = withResetReduxStore(
  ({ resetReduxStore, isAuthenticated }) => {
    const client = useApolloClient();
    const firstRun = React.useRef(true);

    // Clear the redux store and Apollo cache when the user logs out.
    React.useEffect(() => {
      if (!isAuthenticated && !firstRun.current) {
        // User is logged out now. Either they explicitly logged out in this tab, they logged
        // out from another tab, or their token expired. We should reset the app state to prevent
        // leaking any user data. For example, we don't want to persist this user's info in case
        // another user logs in after.
        //
        // Note: unfortunately, this will also clear out the Contact Sales page's data
        // b/c that form uses redux-form. The user doesn't need to be logged in to access
        // that form, so it doesn't make much sense to reset it here. However, this is not
        // really a UX issue. That form is intended for management company employees, who
        // don't log into this app anyways. If this kind of scenario ever does become an
        // issue, we could try to implement a better Redux whitelisting/blacklisting approach.
        resetReduxStore();
        // Clearing the Apollo cache is technically asynchronous, but if the
        // user has clicked "log out", hopefully aren't in the middle of an
        // important request. If their token is expiring then there's not much
        // we can do.
        client.resetStore();
      }

      firstRun.current = false;
    }, [isAuthenticated]);

    return null;
  }
);
