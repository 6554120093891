import { combineReducers, ReducersMapObject, Reducer } from "redux";
import { reducer as formReducer } from "redux-form";
import { searchResults } from "./searchResultsReducer";
import { signup } from "./signupReducer";
import { developer } from "./developerReducer";
import { transactionType } from "./transactionTypeReducer";
import { payment } from "./paymentReducer";
import { cart } from "./cartReducer";
import { attachDocument } from "./attachDocumentReducer";
import { delivery } from "./deliveryReducer";
import { lenderSpecificQuestionnaire } from "./lenderSpecificQuestionnaireReducer";
import { StoreState } from "../store";
import { Action, ActionType } from "../actions";
import { propertyAddressFormName } from "../pageContent/SearchResults/AddressVerification/formMetadata";
import { extendSessionFormName } from "../components/SessionExtender/formMetadata";

// There are a few scenarios where we need to reset the Redux store.
// This file follows a similar approach to the one that Dan Abramov suggested in this post:
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992

const reducers: ReducersMapObject<StoreState, Action> = {
  signup,
  developer,
  searchResults,
  transactionType,
  attachDocument,
  cart,
  payment,
  delivery,
  lenderSpecificQuestionnaire,
  // Redux-Form reducer. You have to pass formReducer under 'form' key,
  // for custom keys look up the docs for 'getFormState'
  form: formReducer,
};

const appReducer = combineReducers<Partial<StoreState>, Action>(reducers);

export const rootReducer: Reducer<Partial<StoreState>, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionType.RESET_STORE:
      return appReducer(undefined, action);
    // For the moment, resetting state from either page should preserve the same
    // set of data - so these two actions can share reducer logic
    case ActionType.RESET_STORE_FROM_SEARCH_RESULTS_PAGE:
    case ActionType.RESET_STORE_FROM_RESALE_TRANSACTION_PAGE:
      if (!state) {
        throw new Error(`
        Expected the Redux state to be defined, but its value was: ${state}.
        The following Redux action was dispatched: ${action.type}
        In this scenario, the Redux store should always be defined.
      `);
      }

      return appReducer(
        {
          searchResults: state.searchResults,
          // If resetting from the search results page, we can clear the
          // `leftSearchResultsPage` now that we're resetting the rest of the store.
          transactionType:
            action.type === ActionType.RESET_STORE_FROM_SEARCH_RESULTS_PAGE
              ? {
                  ...state.transactionType,
                  leftSearchResultsPage: false,
                }
              : state.transactionType,
          // The property address form appears on the search results page, but
          // its state is tracked by redux-form.
          form:
            state.form && state.form[propertyAddressFormName]
              ? {
                  [propertyAddressFormName]:
                    state.form[propertyAddressFormName],
                }
              : {},
        },
        action
      );
    case ActionType.RESET_STORE_FROM_MY_ORDERS_PAGE:
      if (!state) {
        throw new Error(`
        Expected the Redux state to be defined, but its value was: ${state}.
        The Redux store should always be defined when dispatching 
        the 'RESET_STORE_FROM_MY_ORDERS_PAGE' action.
      `);
      }

      return appReducer(
        {
          signup: state.signup,
          form:
            // Retain the Extend Session form's state when resetting. Otherwise,
            // if the user loads the app on the My Orders page, the Extend
            // Session form's fields will be registered, then immediately
            // cleared out by this reset action. This causes the Extend Session
            // form to misbehave (by allowing the user to submit with an
            // empty/invalid password).
            state.form && state.form[extendSessionFormName]
              ? {
                  [extendSessionFormName]: state.form[extendSessionFormName],
                }
              : {},
        },
        action
      );
    default:
      return appReducer(state, action);
  }
};
