import * as React from "react";
import {
  Field,
  GenericField,
  BaseFieldProps,
  WrappedFieldProps,
} from "redux-form";
import {
  FormValidatorFn,
  required as requiredValidator,
  maxLength as maxLengthValidator,
} from "@homewisedocs/validator/lib/form";
import {
  TextField,
  TextFieldProps,
} from "@homewisedocs/components/lib/TextField";
import { normalizeUntrimmedString } from "../../utils/reduxForm";

const DEFAULT_MAX_LENGTH = 40;

type CustomBaseFieldProps<T> = OmitStrict<
  BaseFieldProps<T>,
  "validate" | "normalize"
> & {
  // Override Redux-Form's `validate` prop type with our own version.
  validate?: CustomReduxFormValidator | CustomReduxFormValidator[];
  // The original `normalize` signature has two additional params that are
  // skipped here, `allValues` and `previousAllValues`. However, the
  // user-provided normalize function might run after the original value has
  // been trimmed, and it would be too complex to provide an updated `allValues`
  // param. Those params seem unlikely to be useful anyway.
  normalize?: (currentValue: any, previousValue: any) => any;
};

// Redux-Form allows validators to return more than just a string to represent an error.
// But we only ever need to use a string (to represent an error message).
type CustomReduxFormValidator = (
  value: any,
  allValues?: any,
  props?: any,
  name?: any
) => string | undefined;

type FieldProps = CustomBaseFieldProps<TextFieldProps> &
  OmitStrict<
    TextFieldProps,
    // Omit the MUI TextField's onChange prop, which would prevent callers from
    // using the newValue and previousValue arguments provided by redux-form's
    // onChange.
    "onChange"
  > & {
    /**
     * Maximum length (in characters) for a text field.
     * Applies a length validator when this prop is present.
     */
    maxLength?: number;
  };

const RenderFormTextField = ({
  input,
  label,
  meta: { touched, error, warning },
  helperText,
  ...custom
}: FieldProps & WrappedFieldProps) => (
  <TextField
    {...input}
    {...custom}
    label={label}
    error={touched && Boolean(error || warning)}
    helperText={(touched && error) || helperText}
  />
);

const CustomReduxFormField = Field as new () => GenericField<TextFieldProps>;

type ValidatorType = FormValidatorFn | Array<FormValidatorFn>;

// Redux-form can behave strangely if it receives a new validator function on
// every render, which poses problems when using the max length validator - if
// we always naively build a new max-length validator via `maxLength(len)`, the
// identity of the validator function will be continuously changing. Instead,
// let's memoize the maxLength validation functions so that we always pass the
// same function (by identity) for each max-length validator for a given length.
const memoizedMaxLengthValidators: Record<number, FormValidatorFn | undefined> =
  {};

const getMaxLengthValidator = (len: number): FormValidatorFn => {
  const memoizedValidator = memoizedMaxLengthValidators[len];
  if (!memoizedValidator) {
    const newValidator = maxLengthValidator(len);
    memoizedMaxLengthValidators[len] = newValidator;
    return newValidator;
  }

  return memoizedValidator;
};

export const buildValidators = ({
  customValidator,
  required,
  maxLength,
}: {
  customValidator?: ValidatorType;
  required?: boolean;
  maxLength?: number;
}): Array<FormValidatorFn> => {
  const customValidatorsArray = Array.isArray(customValidator)
    ? customValidator
    : customValidator != null
    ? [customValidator]
    : [];

  const maybeRequiredValidator = required ? [requiredValidator] : [];
  const maybeMaxLengthValidator = maxLength
    ? [getMaxLengthValidator(maxLength)]
    : [];
  return [
    ...maybeRequiredValidator,
    ...maybeMaxLengthValidator,
    ...customValidatorsArray,
  ];
};

export interface FormTextFieldProps extends FieldProps {
  /**
   * Suppresses trimming of leading and trailing whitespace. Defaults to true
   * only if the field is a select or has type="password".
   */
  suppressTrimming?: boolean;
}

export const FormTextField: React.FC<FormTextFieldProps> = ({
  validate,
  required,
  maxLength = DEFAULT_MAX_LENGTH,
  type,
  select,
  normalize: originalNormalize,
  suppressTrimming = select || type === "password",
  ...props
}) => {
  const normalize = React.useMemo<FieldProps["normalize"]>(() => {
    if (suppressTrimming) {
      return originalNormalize;
    }

    return (currentValue, previousValue) => {
      // If trimming is enabled, trim the value _before_ passing it to the
      // provided normalize function - for most use cases, it would make more
      // sense to strip whitespace before than after.
      const currentTrimmedValue = normalizeUntrimmedString(
        currentValue,
        previousValue
      );

      return originalNormalize
        ? originalNormalize(currentTrimmedValue, previousValue)
        : currentTrimmedValue;
    };
  }, [originalNormalize, suppressTrimming]);

  return (
    <CustomReduxFormField
      // TODO: figure out how to remove type assertion
      {...(props as any)}
      type={type}
      select={select}
      normalize={normalize}
      required={required}
      validate={buildValidators({
        customValidator: validate,
        required,
        maxLength,
      })}
      component={RenderFormTextField}
    />
  );
};
