import { Action, ActionType } from "../actions";

interface UploadedForm {
  id: string;
  filename: string;
}

type InitialState = Readonly<{
  lsqId?: undefined;
  newFormUpload: false;
  uploadedForm?: undefined;
  recordExtraLsqInfo: false;
}>;

// User has decided they do not want a lender-specific questionnaire.
type NoLsqState = Readonly<{
  lsqId?: undefined;
  newFormUpload: false;
  uploadedForm?: UploadedForm;
  // Note: If the user initially wants an LSQ, they might set this to true. Even
  // if they then switch to a Standard Qnr, we should keep this flag true in
  // case the user switches back to an LSQ.
  recordExtraLsqInfo: boolean;
}>;

// User has decided they want an LSQ, but they haven't necessarily decided which one.
type LsqState = Readonly<{
  lsqId?: string | undefined;
  newFormUpload: false;
  uploadedForm?: UploadedForm;
  recordExtraLsqInfo: boolean;
}>;

type NewFormUploadState = Readonly<{
  lsqId: undefined;
  newFormUpload: true;
  uploadedForm?: UploadedForm;
  recordExtraLsqInfo: boolean;
}>;

type NewFormUploadCompleteState = Readonly<{
  lsqId: undefined;
  newFormUpload: true;
  uploadedForm: UploadedForm;
  recordExtraLsqInfo: boolean;
}>;

export type State =
  | InitialState
  | NoLsqState
  | LsqState
  | NewFormUploadState
  | NewFormUploadCompleteState;

const INITIAL_STATE: InitialState = {
  newFormUpload: false,
  recordExtraLsqInfo: false,
};

export const lenderSpecificQuestionnaire = (
  state: State = INITIAL_STATE,
  action: Action
): State => {
  switch (action.type) {
    case ActionType.CLEAR_LSQ_SELECTION:
      return {
        ...state,
        lsqId: undefined,
        newFormUpload: false,
      };
    case ActionType.SELECT_LSQ_WITH_ID:
      return {
        ...state,
        lsqId: action.payload,
        newFormUpload: false,
      };
    case ActionType.SELECT_MY_FORM_IS_NOT_HERE:
      return {
        ...state,
        lsqId: undefined,
        newFormUpload: true,
      };
    case ActionType.SET_NEW_LSQ_FORM:
      return {
        ...state,
        lsqId: undefined,
        newFormUpload: true,
        uploadedForm: {
          id: action.payload.id,
          filename: action.payload.filename,
        },
      };
    case ActionType.TOGGLE_RECORD_EXTRA_LSQ_INFO:
      return {
        ...state,
        recordExtraLsqInfo: !state.recordExtraLsqInfo,
      };
    default:
      return state;
  }
};
