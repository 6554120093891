import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import BetterBusinessIcon from "../../images/better_business_bureau.svg";

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    icon: {
      width: "16rem",
      display: "block",
      [breakpoints.down("sm")]: {
        marginBottom: "6rem",
      },
      // Setting max width/height seems to avoid an issue with IE 11 (and
      // possibly only Windows 7) where the logo would appear distorted.
      // See: https://github.com/homewisedocs/requestor-app/issues/305
      maxWidth: "100%",
      maxHeight: "100%",
    },
  })
);

export const BBBIcon: React.FC = () => {
  const classes = useStyles();

  return (
    <img
      className={classes.icon}
      src={BetterBusinessIcon}
      alt="Accredited Business"
    />
  );
};
