import { Action, ActionType } from "../actions";

interface HardCopyState {
  sendHardCopy: boolean;
}

export type ShareOrderEmailsState = Readonly<{
  numShareOrderEmails: number;
}>;

type SendTextNotificationState = Readonly<{
  sendTextNotification: boolean;
}>;

type OrderAlertsState = Readonly<{
  orderAlertDismissed: boolean;
  specialOrderAlertDismissed: boolean;
}>;

export type State = HardCopyState &
  ShareOrderEmailsState &
  SendTextNotificationState &
  OrderAlertsState;

const INITIAL_STATE: State = {
  sendHardCopy: false,
  // Display 2 "share order" email fields initially
  numShareOrderEmails: 2,
  sendTextNotification: false,

  orderAlertDismissed: false,
  specialOrderAlertDismissed: false,
};

export const MAX_SHARE_ORDER_EMAILS = 10;

export const delivery = (
  state: State = INITIAL_STATE,
  action: Action
): State => {
  switch (action.type) {
    case ActionType.TOGGLE_SEND_HARD_COPY:
      if (state.sendHardCopy) {
        return {
          ...state,
          sendHardCopy: false,
        };
      }
      return {
        ...state,
        sendHardCopy: true,
      };
    case ActionType.TOGGLE_SEND_TEXT_NOTIFICATION:
      return {
        ...state,
        sendTextNotification: !state.sendTextNotification,
      };
    case ActionType.ADD_SHARE_ORDER_EMAIL:
      if (state.numShareOrderEmails < MAX_SHARE_ORDER_EMAILS) {
        return {
          ...state,
          numShareOrderEmails: state.numShareOrderEmails + 2,
        };
      }
      return state;
    case ActionType.DISMISS_ORDER_ALERT:
      return {
        ...state,
        orderAlertDismissed: true,
      };
    case ActionType.DISMISS_SPECIAL_ORDER_ALERT:
      return {
        ...state,
        specialOrderAlertDismissed: true,
      };
    default:
      return state;
  }
};
