import * as React from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch, useLocation } from "react-router";
import { Route, Switch, Redirect, RouteProps } from "react-router-dom";
import "react-day-picker/lib/style.css";
import "./react-day-picker.css";
import { fetchChunkWithRetries } from "@homewisedocs/client-utils/lib/chunkFetching";
import { useAppLocationTracking } from "@homewisedocs/components/lib/useAppLocationTracking";
import { RobotsConsumer } from "@homewisedocs/components/lib/Robots";
import "./requestor-app.css";
import { AppIdentityTracking } from "./components/AppIdentityTracking";
import { ResetStateOnLogout } from "./components/ResetStateOnLogout";
import { title } from "./constants/meta";
import { PageNotFoundPage } from "./pageContent/PageNotFound";
import LoadingPage from "./pageContent/LoadingPage";
import {
  ROOT,
  LOGIN,
  HOMEPAGE,
  CONTACT_SALES,
  SIGN_UP,
  FORGOT_USERNAME,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SOLUTIONS,
  MY_ACCOUNT,
  SEARCH_RESULTS_ASSOCIATION_WITH_ID,
  SEARCH_RESULTS_ADDRESS_WITH_ID,
  RESALE_TRANSACTION,
  SELECT_ITEMS,
  SELECT_MASTER_HOA_ITEMS,
  LSQ,
  REVIEW_ORDER,
  TRANSACTION_DETAILS,
  DELIVERY,
  CONFIRM_ORDER,
  MY_ORDERS,
  PAYMENT_METHOD,
  ADDED_RUSH_PAYMENT,
  UPDATE_REQUEST_PAYMENT,
  ORDER_STATUS,
  PAY_FOR_ORDER_STATUS,
  THIRD_PARTY_PAYMENT,
} from "./constants/routes";
import {
  ErrorBoundary,
  ErrorBoundaryVariant,
} from "./containers/ErrorBoundary";
import { useAuth } from "./components/Auth";
import { Navbar } from "./components/Navbar";
import { GlobalModals } from "./components/GlobalModals";

const pageFetchers = {
  Homepage: () => import(/* webpackChunkName: "Homepage" */ "./pages/home"),
  Login: () => import(/* webpackChunkName: "Login" */ "./pages/login"),
  SignUp: () => import(/* webpackChunkName: "SignUp" */ "./pages/sign-up"),
  ForgotUsername: () =>
    import(/* webpackChunkName: "ForgotUsername" */ "./pages/forgot-username"),
  ForgotPassword: () =>
    import(/* webpackChunkName: "ForgotPassword" */ "./pages/forgot-password"),
  ResetPassword: () =>
    import(/* webpackChunkName: "ResetPassword" */ "./pages/reset-password"),
  SolutionsPages: () =>
    import(/* webpackChunkName: "SolutionsPages" */ "./pages/solutions"),
  ContactSales: () =>
    import(/* webpackChunkName: "ContactSales" */ "./pages/contact-sales"),
  CoreApp: () => import(/* webpackChunkName: "CoreApp" */ "./CoreApp"),
};

// Like React.lazy, but after the component in question is fetched, we pre-fetch
// all the other pages of the app in the background so future route changes will
// happen instantly.
// See: https://hackernoon.com/lazy-loading-and-preloading-components-in-react-16-6-804de091c82d
// This should improve perceived performance for users as well as resolve an issue
// where old versions of the app could throw errors when attempting to fetch
// chunks that had been deleted from the server following a re-deployment:
// https://github.com/homewisedocs/requestor-app/issues/703
const reactLazyWithPrefetching: typeof React.lazy = importCallback =>
  React.lazy(() =>
    fetchChunkWithRetries(importCallback).then(fetchedModule => {
      // Pre-fetch every other page now that the page the user has routed to has
      // finished fetching. Catch import failures so that they don't go
      // unhandled and send the user to the error page; if fetching one of
      // these pages fails, webpack will try to fetch it again later if they
      // navigate to that page's route (with 3 tries).
      //
      // We can skip this when Cypress is running; pre-fetching these
      // scripts slows down the test runner for no benefit.
      if (!window.Cypress) {
        Object.values(pageFetchers).forEach(
          (pageFetcher: () => Promise<unknown>) =>
            pageFetcher().catch(() => null)
        );
      }

      return fetchedModule;
    })
  );

// Use React's Lazy/Suspense to defer loading of components that haven't been
// rendered yet.
const Homepage = reactLazyWithPrefetching(pageFetchers.Homepage);
const LoginPage = reactLazyWithPrefetching(pageFetchers.Login);
const SignUpPage = reactLazyWithPrefetching(pageFetchers.SignUp);
const ForgotUsernamePage = reactLazyWithPrefetching(
  pageFetchers.ForgotUsername
);
const ForgotPasswordPage = reactLazyWithPrefetching(
  pageFetchers.ForgotPassword
);
const ResetPasswordPage = reactLazyWithPrefetching(pageFetchers.ResetPassword);
const SolutionsPages = reactLazyWithPrefetching(pageFetchers.SolutionsPages);
const ContactSales = reactLazyWithPrefetching(pageFetchers.ContactSales);
const CoreApp = reactLazyWithPrefetching(pageFetchers.CoreApp);

/**
 * If an authenticated user visits certain routes, we should redirect them to
 * the My Orders page. This component helps simplify that routing logic.
 *
 * The `children` will only be rendered if the path matches and if the user
 * _isn't_ logged in.
 */
const RedirectIfAuthenticated: React.FC<Pick<RouteProps, "path" | "exact">> = ({
  path,
  exact,
  children,
}) => {
  const match = useRouteMatch({
    path,
    exact,
  });
  const { authProps } = useAuth();

  if (!match) {
    return null;
  }

  if (authProps.isAuthenticated) {
    return <Redirect to={MY_ORDERS} />;
  }

  // Wrap `children` in fragment to circumvent TS issue with React.FC:
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
  return <>{children}</>;
};

export const App = () => {
  const { authProps } = useAuth();

  return (
    <>
      <ErrorBoundary variant={ErrorBoundaryVariant.NON_CRITICAL_SUBTREE}>
        <>
          <RobotsConsumer>
            {({ robotsMetaElement }) => (
              <Helmet title={title}>{robotsMetaElement}</Helmet>
            )}
          </RobotsConsumer>
          <AppLocationTracking />
          <AppIdentityTracking authProps={authProps} />
        </>
      </ErrorBoundary>
      <GlobalModals />
      <ResetStateOnLogout isAuthenticated={authProps.isAuthenticated} />
      <Navbar />
      <React.Suspense fallback={<LoadingPage />}>
        <Switch>
          {/* Root/Homepage Routes */}
          <RedirectIfAuthenticated exact path={ROOT}>
            <Homepage />
          </RedirectIfAuthenticated>
          {/* 
               Some users land on our site at homewisedocs.com// (with 2 slashes).
               We don't know exactly why. It might be caused by some old links.
               If the user logs in, we redirect them back to this "//" route,
               which unfortunately results in an error like this:
               SecurityError: Failed to execute 'replaceState' on 'History': 
               A history state object with URL 'https:' cannot be created in a 
               document with origin 'https://www.homewisedocs.com' and URL 
               'https://www.homewisedocs.com/sign-up?returnUrl=%2F%2F'
       
               See: https://github.com/homewisedocs/requestor-app/issues/985
            */}
          <Route
            path="//"
            // Even if the path doesn't match "//" exactly, we should still
            // redirect the user. We don't have any legitimate use cases for
            // using "//" in our paths.
            exact={false}
          >
            <Redirect to={ROOT} />
          </Route>
          <Route exact path={HOMEPAGE}>
            <Homepage />
          </Route>

          <Route path={LOGIN}>
            <LoginPage />
          </Route>
          <RedirectIfAuthenticated path={SIGN_UP}>
            <SignUpPage />
          </RedirectIfAuthenticated>

          <RedirectIfAuthenticated path={FORGOT_USERNAME}>
            <ForgotUsernamePage />
          </RedirectIfAuthenticated>

          <RedirectIfAuthenticated path={FORGOT_PASSWORD}>
            <ForgotPasswordPage />
          </RedirectIfAuthenticated>
          <RedirectIfAuthenticated path={RESET_PASSWORD}>
            <ResetPasswordPage />
          </RedirectIfAuthenticated>

          <Route path={CONTACT_SALES}>
            <ContactSales />
          </Route>

          <Route path={SOLUTIONS}>
            <SolutionsPages />
          </Route>

          {/* Keep the "core" functionality of the client (the My Orders
              page, the order flow process, etc) in a separate module so we can
              delay importing it until necessary and speed up the load time of
              simpler pages that prospective users and search engines are likely
              to land on. */}
          <Route
            path={[
              MY_ACCOUNT,
              SEARCH_RESULTS_ASSOCIATION_WITH_ID,
              SEARCH_RESULTS_ADDRESS_WITH_ID,
              RESALE_TRANSACTION,
              SELECT_ITEMS,
              SELECT_MASTER_HOA_ITEMS,
              LSQ,
              REVIEW_ORDER,
              TRANSACTION_DETAILS,
              DELIVERY,
              CONFIRM_ORDER,
              MY_ORDERS,
              PAYMENT_METHOD,
              ADDED_RUSH_PAYMENT,
              UPDATE_REQUEST_PAYMENT,
              ORDER_STATUS,
              PAY_FOR_ORDER_STATUS,
              THIRD_PARTY_PAYMENT,
            ]}
          >
            <CoreApp />
          </Route>

          <Route>
            <PageNotFoundPage />
          </Route>
        </Switch>
      </React.Suspense>
    </>
  );
};

const AppLocationTracking: React.FC = () => {
  // Note: in general, we would simply call these hooks in the `App` component.
  // However, the `useLocation` hook will cause the component it's called in to
  // re-render any time the location changes. In the interest of performance,
  // it's better to call the `useLocation` hook here since it's much simpler and
  // quicker to render this component than `App`.
  const { pathname } = useLocation();
  useAppLocationTracking({ pathname });
  return null;
};
