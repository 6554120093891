import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";
import { US_COUNTRY_CODE } from "@homewisedocs/common/lib/constants/geo";
import { FormValues } from "../../utils/reduxForm";

export enum LenderFormTypeValues {
  STANDARD_QNR = "STANDARD_QNR",
  LSQ = "LSQ",
}

type LsqFormFieldName =
  | "lenderFormType"
  | "lenderFormLender"
  | "standardQnrAcknowledgment"
  | "lsqAcknowledgment";

export const lsqFormFieldNames: FormFieldNameMap<LsqFormFieldName> = {
  lenderFormType: "lenderFormType",
  lenderFormLender: "lenderFormLender",
  standardQnrAcknowledgment: "standardQnrAcknowledgment",
  lsqAcknowledgment: "lsqAcknowledgment",
};

export const lsqBorrowerInfoSectionName = "lsqBorrowerInfo";

type LsqBorrowerInfoSectionFormFieldName = "firstName" | "lastName";

export const lsqBorrowerInfoSectionFormFieldNames: FormFieldNameMap<LsqBorrowerInfoSectionFormFieldName> =
  {
    firstName: "firstName",
    lastName: "lastName",
  };

export const lsqLenderInfoSectionName = "lsqLenderInfo";

type LsqLenderInfoSectionFormFieldName =
  | "companyName"
  | "contactFirstName"
  | "contactLastName"
  | "email"
  | "phone"
  | "fax"
  | "streetAddress"
  | "unit"
  | "city"
  | "state"
  | "postalCode"
  | "country";

export const lsqLenderInfoSectionFormFieldNames: FormFieldNameMap<LsqLenderInfoSectionFormFieldName> =
  {
    companyName: "companyName",
    contactFirstName: "contactFirstName",
    contactLastName: "contactLastName",
    email: "email",
    phone: "phone",
    fax: "fax",
    streetAddress: "streetAddress",
    unit: "unit",
    city: "city",
    state: "state",
    postalCode: "postalCode",
    country: "country",
  };

export interface LsqFormValues extends FormValues<LsqFormFieldName> {
  [lsqBorrowerInfoSectionName]: FormValues<LsqBorrowerInfoSectionFormFieldName>;
  [lsqLenderInfoSectionName]: FormValues<LsqLenderInfoSectionFormFieldName>;
}

export const lsqFormName = "Lsq";

export const INITIAL_LENDER_COUNTRY_CODE = US_COUNTRY_CODE;
