import { GenericNavbarDataTest } from "@homewisedocs/components/lib/GenericNavbar/dataTest";

export const DataTest = {
  ...GenericNavbarDataTest,
  Navbar: "Navbar",
};

export const AuthMenuDataTest = {
  dropdownMenu: "AuthMenuDropdownMenu",
};
