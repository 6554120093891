import { PageWrapper } from "../components/PageWrapper";
import { CircularProgress } from "@material-ui/core";

const LoadingPage = () => (
  <PageWrapper requireAuth={false}>
    <CircularProgress />
  </PageWrapper>
);

export default LoadingPage;
