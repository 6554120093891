type FunctionType = (...args: any[]) => any;
interface ActionCreatorsMapObject {
  [actionCreator: string]: FunctionType;
}

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;

export interface Action<T extends string> {
  type: T;
}
export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}

/**
 * Creates a flux standard action given a `type` and optional `payload`.
 */
export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(
  type: T,
  payload: P
): ActionWithPayload<T, P>;
export function createAction(type: string, payload?: any) {
  return typeof payload === "undefined" ? { type } : { type, payload };
}
