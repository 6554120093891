import { TransactionType } from "../__generated__/gql";

export enum UiTransactionType {
  BuySell = "buying or selling",
  BuySellBehalf = "behalf of buyer or seller",
  Refinance = "refinance",
  RefinanceBehalf = "behalf of refinancer",
  Other = "none of above",
}

/**
 * A mapping from API transaction types to UI transaction types. Note: We do not have a 1-to-1 mapping from API
 * transaction types to UI transaction types. The API transaction type is a "lossily compressed" version of the
 * UI transaction type b/c the API doesn't have a notion of "buy/sell behalf". It doesn't distinguish the "behalf" part.
 */
export const transactionTypeMappingApiToUi: Record<
  TransactionType,
  UiTransactionType
> = {
  [TransactionType.RESALE]: UiTransactionType.BuySell,
  [TransactionType.REFINANCE]: UiTransactionType.Refinance,
  [TransactionType.OTHER]: UiTransactionType.Other,
};
