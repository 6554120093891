/**
 * Constants used for interacting with the Redux store in Cypress.
 *
 * IMPORTANT: Do NOT move these constants into the file where the Redux store is created. The reason
 * is that we need to import these constants inside our Cypress tests. If you move these constants
 * into the file where the Redux store is created, then that Redux file will run prematurely (b/c we'll
 * end up importing that file in our Cypress spec files).
 */

// The key used to attach the Redux store to the browser's `window ` object
export const WINDOW_REDUX_STORE_KEY = "hwdReduxStore";

// The event for emitting the Redux store, which is very helpful for seeding the Redux store
// in Cypress tests.
export const EMIT_REDUX_STORE = "emit:hwdReduxStore";
