import * as React from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { H4, HeaderColor } from "@homewisedocs/components/lib/Header";

const useStyles = makeStyles(({ custom: { colors }, breakpoints }) =>
  createStyles({
    header: {
      color: colors.white,
      marginBottom: "2rem",
      fontWeight: 600,
    },
    list: {
      fontSize: "1.4rem",
      listStyle: "none",
      lineHeight: 1.4,
      margin: "0",
      // Set padding 0 since ie11 was adding unwanted padding
      padding: "0",
      "& > li:not(:last-child)": {
        marginBottom: "1.5rem",
      },
    },
    link: {
      textDecoration: "none",
      color: colors.secondary,
    },
    wrapper: {
      textAlign: "left",
      [breakpoints.down("sm")]: {
        textAlign: "center",
        marginBottom: "3rem",
      },
      [breakpoints.up("md")]: {
        maxWidth: "19rem",
      },
      "&:not(:last-child)": {
        [breakpoints.only("md")]: {
          marginRight: "4rem",
        },
        [breakpoints.up("lg")]: {
          marginRight: "6rem",
        },
      },
    },
  })
);

export interface Props {
  header: string;
  linkItems: Array<{
    label: string;
    path: string;
    /**
     * If true, open the link in a new tab. Defaults to false.
     */
    openNewTab?: boolean;
    linkProps?: OmitStrict<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      "className" | "href" | "children"
    >;
  }>;
}

export const LinkItems: React.FC<Props> = ({ header, linkItems, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} {...props}>
      <H4 color={HeaderColor.primary} className={classes.header}>
        {header}
      </H4>
      <nav>
        <ul className={classes.list}>
          {linkItems.map(({ label, path, openNewTab, linkProps }, index) => (
            <li key={index}>
              {openNewTab ? (
                <a
                  href={path}
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  {...linkProps}
                >
                  {label}
                </a>
              ) : (
                <Link to={path} className={classes.link} {...linkProps}>
                  {label}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
