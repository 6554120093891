import { VerifyAddressQuery } from "../__generated__/gql";
import { PlaceOrderErrorCode } from "../__generated__/gql";

export const PLACE_ORDER_GRAPHQL_ERROR_CODE = "GRAPHQL_ERROR";

// [June 2023] Note - since we no longer use LogRocket, which we previously used
// to track events, these handlers are currently no-ops. But it seems like a
// shame to remove these calls from the various places they've been added, since
// they may be useful again in the event that we want to start tracking these
// sorts of events with another service in the future.
export const eventTrackers = {
  // Search Results events
  trackVerifyAddressQueryFailure() {},

  trackVerifyAddressQuerySuccess(queryResult: VerifyAddressQuery) {},

  trackPropertySectionActionDispatch(actionType: string) {},

  // Place Order events
  trackPlaceOrderAttempt() {},
  trackPlaceOrderFailure(
    errorCode: PlaceOrderErrorCode | typeof PLACE_ORDER_GRAPHQL_ERROR_CODE
  ) {},
  trackPlaceOrderSuccess(confirmationNumber: string) {},

  // Contact Sales events
  trackContactSalesAttempt() {},
  trackContactSalesFailure() {},
  trackContactSalesSuccess() {},

  // Extend Session events
  trackExtendSessionOffer() {},
};
