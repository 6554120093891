import { Action, ActionType } from "../actions";

type InitialState = Readonly<{
  firstMyOrdersVisitAfterSignUp: false;
}>;

type CompletedSignUp = Readonly<{
  firstMyOrdersVisitAfterSignUp: true;
}>;

export type State = InitialState | CompletedSignUp;

const INITIAL_STATE: State = {
  firstMyOrdersVisitAfterSignUp: false,
};

export const signup = (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_FIRST_MY_ORDERS_VISIT_AFTER_SIGN_UP:
      return {
        ...state,
        firstMyOrdersVisitAfterSignUp: action.payload,
      };
    default:
      return state;
  }
};
