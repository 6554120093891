import { formatISO, parseISO, isValid as isValidDate } from "date-fns";

export const serializeReduxDate = (date: Date | null | undefined) =>
  date ? formatISO(date) : undefined;

export const deserializeReduxDate = (
  serializedDate: string | null | undefined
) => {
  if (serializedDate) {
    const parsedDate = parseISO(serializedDate);
    if (!isValidDate(parsedDate)) {
      throw new Error(
        `Failed to deserialize date from Redux store. Received: ${serializedDate}`
      );
    }
    return parsedDate;
  } else {
    return undefined;
  }
};
