import * as React from "react";
import { useHistory } from "react-router";
import {
  buildLoginLink,
  SIGN_UP,
  LOGIN,
  buildSignUpLink,
} from "../../constants/routes";
import { History } from "history";

interface Props {
  children: (renderProps: ChildProps) => React.ReactElement | null;
}

interface ChildProps {
  redirectToLoginPage: LoginRedirectFn;
  redirectToSignUpPage: SignUpRedirectFn;
}

interface RedirectToLoginOptions {
  returnUrl?: string;
  includeCallingPageInHistory?: boolean;
}
type LoginRedirectFn = (options?: RedirectToLoginOptions) => void;

interface RedirectToSignUpOptions {
  returnUrl?: string;
}
type SignUpRedirectFn = (options?: RedirectToSignUpOptions) => void;

const redirectToPage = ({
  history,
  returnUrl,
  pageRoute,
  buildPageLink,
  includeCallingPageInHistory,
}: {
  history: History;
  returnUrl?: string;
  pageRoute: string;
  buildPageLink: (returnUrl: string) => string;
  includeCallingPageInHistory: boolean;
}) => {
  // pathname + search + hash includes everything in the URL after the protocol and host.
  const { pathname, search, hash } = window.location;
  const effectiveReturnUrl = returnUrl || `${pathname}${search}${hash}`;

  // If we're not already on the target page, redirect there
  if (pathname.substr(0, pageRoute.length) !== pageRoute) {
    const destination = buildPageLink(effectiveReturnUrl);

    if (includeCallingPageInHistory) {
      history.push(destination);
    } else {
      history.replace(destination);
    }
  }
};

const redirectToLoginPage = (
  history: History,
  options: RedirectToLoginOptions = {}
) => {
  const { returnUrl, includeCallingPageInHistory = true } = options;
  redirectToPage({
    history,
    returnUrl,
    pageRoute: LOGIN,
    buildPageLink: buildLoginLink,
    includeCallingPageInHistory,
  });
};

const redirectToSignUpPage = (
  history: History,
  options: RedirectToSignUpOptions = {}
) => {
  const { returnUrl } = options;
  redirectToPage({
    history,
    returnUrl,
    pageRoute: SIGN_UP,
    buildPageLink: buildSignUpLink,
    includeCallingPageInHistory: true,
  });
};

export const WithAuthRedirects: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  return children({
    redirectToLoginPage: options => redirectToLoginPage(history, options),
    redirectToSignUpPage: options => redirectToSignUpPage(history, options),
  });
};
