import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";
import { CancellationReason } from "../../../../__generated__/gql";

type FormSectionName = "cancellationReason" | "comments";

export const FormSectionNames: FormFieldNameMap<FormSectionName> = {
  cancellationReason: "cancellationReason",
  comments: "comments",
};

export interface CancelOrderModalFormValues {
  [FormSectionNames.cancellationReason]: CancellationReason;
  [FormSectionNames.comments]: string;
}

export const CancelOrderFormName = "cancelOrder";
