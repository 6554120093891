import { useRouteMatch, useLocation } from "react-router";
import {
  MY_ORDERS,
  ORDER_STATUS,
  PAY_FOR_ORDER_STATUS,
} from "../constants/routes";

/**
 * Builds a route that can be used to link to the My Orders page. If the user
 * is _already_ on the My Orders page, this will return a route matching the
 * user's current location. This is useful for building links that won't
 * destroy the user's filtering state if they're already on the My Orders page.
 */
export const useSmartMyOrdersRoute = () => {
  const routeMatch = useRouteMatch(MY_ORDERS);
  const { pathname, search, hash } = useLocation();
  return routeMatch ? `${pathname}${search}${hash}` : MY_ORDERS;
};

/**
 * Returns true if the user is on the Order Status page or the (very similar)
 * Pay For Order Status page.
 */
export const useUserIsOnOrderStatusPage = (): boolean => {
  const userIsOnOrderStatusPage = useRouteMatch(ORDER_STATUS);
  const userIsOnPayForOrderStatusPage = useRouteMatch(PAY_FOR_ORDER_STATUS);
  return Boolean(userIsOnOrderStatusPage || userIsOnPayForOrderStatusPage);
};
