import {
  createStyles,
  withStyles,
  WithStyles,
  Slide,
  Theme,
} from "@material-ui/core";
import { OurSolutions } from "../SolutionsMegaMenu/OurSolutions";
import { Roles } from "../SolutionsMegaMenu/Roles";
import { Industries } from "../SolutionsMegaMenu/Industries";
import { NAVBAR_HEIGHT } from "../Navbar/shared";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { SolutionsMenuDataTest } from "../SolutionsMegaMenu/DataTest";

const styles = ({ custom: { colors } }: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      textAlign: "center",
      flexDirection: "column",
      backgroundColor: "white",
      width: "100%",
      position: "fixed",
      top: NAVBAR_HEIGHT,
      bottom: "0",
      overflow: "scroll !important",
      paddingBottom: "3rem",
      // Added zIndex to ensure Solutions content is not covered by Vertical Menu links. Some links are using Slide,
      // which adds transform/transition css properties causing those elements to overlap the Solutions content
      // that is using a position fixed.
      zIndex: 100,
    },
    arrowBackIcon: {
      color: colors.secondaryDark,
      cursor: "pointer",
      height: "4rem",
      width: "4rem",
      margin: "2rem",
    },
    verticalSeparator: {
      border: `1px solid ${colors.lighterGray}`,
      width: "28rem",
      margin: "0 auto",
    },
  });

interface Props {
  open: boolean;
  onClose: () => void;
  closeHamburgerMenu: () => void;
}

export const Solutions = withStyles(styles)(
  ({
    open,
    onClose,
    closeHamburgerMenu,
    classes,
  }: Props & WithStyles<typeof styles>) => (
    // The MUI docs show an example of passing mountOnEnter and unmountOnExit
    // to `<Slide/>`: https://material-ui.com/utils/transitions/#slide
    // It's not clear if we necessarily need these props in this case.
    // However, they helped resolve a Cypress error where Cypress was viewing
    // this as a `positioned: "fixed"` element covering an element that is no
    // longer considered visible.
    // Issue: https://github.com/cypress-io/cypress/issues/1379
    <Slide in={open} direction="left" timeout={250} mountOnEnter unmountOnExit>
      <div
        data-test={SolutionsMenuDataTest.solutionsMenu}
        className={classes.wrapper}
      >
        <ArrowBack
          data-test={SolutionsMenuDataTest.backArrow}
          classes={{
            root: classes.arrowBackIcon,
          }}
          onClick={onClose}
        />
        <OurSolutions />
        <div className={classes.verticalSeparator} />
        <Roles onLinkItemClick={closeHamburgerMenu} />
        <Industries onLinkItemClick={closeHamburgerMenu} />
      </div>
    </Slide>
  )
);
