import * as React from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import HWDLogoWhiteText from "@homewisedocs/components/lib/images/HWD_Logo_White.svg";
import { HOMEPAGE } from "../../constants/routes";

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    icon: {
      width: "22rem",
      display: "block",
      marginBottom: "4rem",
      [breakpoints.down("sm")]: {
        marginBottom: "6rem",
      },
      // Setting max width/height seems to avoid an issue with IE 11 (and
      // possibly only Windows 7) where the logo would appear distorted.
      // See: https://github.com/homewisedocs/requestor-app/issues/305
      maxWidth: "100%",
      maxHeight: "100%",
    },
  })
);

export const FooterLogo: React.FC = () => {
  const classes = useStyles();

  return (
    <Link to={HOMEPAGE}>
      <img className={classes.icon} src={HWDLogoWhiteText} alt="HomeWiseDocs" />
    </Link>
  );
};
