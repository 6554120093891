import { removeNonDigits } from "@homewisedocs/common/lib/misc";

export type FormValues<FieldNames extends keyof any> = Readonly<{
  [F in FieldNames]?: string | undefined;
}>;

type NullableString = string | null | undefined;

export const normalizePhone = (val: NullableString) =>
  typeof val === "string" ? removeNonDigits(val) : val;

/**
 * When passed as the `normalize` prop for a redux-form field, trims leading and
 * trailing whitespace from the value on blur.
 *
 * NOTE: in Chromium-based browsers, inputs with `type="email"` will
 * automatically trim the underlying input value. By extension, adding leading
 * or trailing whitespace doesn't trigger a change event. The values will still
 * _appear_ untrimmed in the UI if the user adds leading/trailing whitespace,
 * though.
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email
 */
export const normalizeUntrimmedString = (
  val: NullableString,
  previousVal: NullableString
) => {
  // `redux-form` calls `normalize` on a field after every change event. We
  // don't want to trim the value that often; otherwise the user could never add
  // a space at the end of the value without it immediately being trimmed away.
  // However, `normalize` is _also_ called on blur, in which case - unlike
  // change events - the new value is the same as the previous value. We can
  // check if those values match in order to trim the value only on blur.
  if (val === previousVal) {
    return val?.trim();
  }

  return val;
};
