import * as React from "react";
import { Link, NavLink, LinkProps } from "react-router-dom";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { GenericNavbar } from "@homewisedocs/components/lib/GenericNavbar";
import { DataTest } from "./dataTest";
import { HOMEPAGE, FAQS } from "../../constants/routes";
import { VerticalMenu } from "../VerticalMenu";
import { OverlayWrapper } from "../OverlayWrapper";
import { Hamburger } from "@homewisedocs/components/lib/Hamburger";
import { AuthMenu } from "./AuthMenu";
import { defaultNavbarFontSize } from "./shared";
import { OVERLAY_WRAPPER_Z_INDEX } from "../OverlayWrapper";
import { usePrevious } from "@homewisedocs/client-utils/lib/usePrevious";
import { SolutionsMegaMenu } from "../SolutionsMegaMenu";
import { useResponsive } from "@homewisedocs/components/lib/Responsive";
import { useSmartMyOrdersRoute } from "../../utils/routing";

const useStyles = makeStyles(({ custom: { colors } }) =>
  createStyles({
    link: {
      color: "white",
      fontSize: defaultNavbarFontSize,
      textDecoration: "none",
      "&:hover": {
        color: colors.secondary,
      },
      padding: "1rem",
    },
    menuItemsWrapper: {
      maxWidth: "30rem",
      justifyContent: "space-between",
      display: "flex",
      width: "100%",
      alignItems: "center",
    },
    verticalMenuVisible: {
      // When the vertical menu is visible, the navbar should use the same
      // z-index as the menu. That will help the navbar and menu act as a single
      // cohesive element. Otherwise, there might be a z-index conflict if the
      // app displays a modal after the vertical menu had been opened.
      // See https://github.com/homewisedocs/requestor-app/issues/2275
      zIndex: OVERLAY_WRAPPER_Z_INDEX,
    },
  })
);

export const Navbar = React.memo(() => {
  const { mobile } = useResponsive();
  const classes = useStyles();

  const prevMobile = usePrevious(mobile);
  const [verticalMenuVisible, setVerticalMenuVisible] = React.useState(false);
  React.useEffect(() => {
    const switchedToDesktop = prevMobile && !mobile;
    if (switchedToDesktop && verticalMenuVisible) {
      // Doesn't make sense to display the vertical menu anymore since we're
      // no longer in mobile mode. Close the menu automatically.
      setVerticalMenuVisible(false);
    }
  }, [prevMobile, mobile, verticalMenuVisible]);

  return (
    <div data-test={DataTest.Navbar}>
      {verticalMenuVisible && (
        <OverlayWrapper>
          <VerticalMenu onClose={() => setVerticalMenuVisible(false)} />
        </OverlayWrapper>
      )}
      <GenericNavbar
        LogoLinkComponent={NavbarLogoLink}
        className={
          verticalMenuVisible ? classes.verticalMenuVisible : undefined
        }
      >
        {mobile ? (
          <Hamburger
            open={verticalMenuVisible}
            onClick={() => setVerticalMenuVisible(!verticalMenuVisible)}
          />
        ) : (
          <>
            <NavbarMenuItems
              className={classes.menuItemsWrapper}
              itemClassName={classes.link}
            />
            <AuthMenu />
          </>
        )}
      </GenericNavbar>
    </div>
  );
});

export function NavbarMenuItems({
  className,
  itemClassName,
}: {
  className: string;
  itemClassName: string;
}) {
  const smartMyOrdersRoute = useSmartMyOrdersRoute();

  return (
    <div className={className}>
      <NavbarTextMenuItem
        name="My Orders"
        to={smartMyOrdersRoute}
        className={itemClassName}
        // Add 'nofollow' so search engines don't index the 'My Orders' page,
        // which will (perhaps confusingly) send anonymous users to the login
        // page.
        rel="nofollow"
      />
      <SolutionsMegaMenu />
      {/* Because we are using an external url we need to use an 'a' tag
          since react-router doesn't handle external links with <Link>. Reference:
          https://github.com/ReactTraining/react-router/issues/1147 */}
      <a
        className={itemClassName}
        href={FAQS}
        target="_blank"
        rel="noopener noreferrer"
      >
        FAQs
      </a>
    </div>
  );
}

const NavbarLogoLink: React.FC = ({ children }) => (
  <Link to={HOMEPAGE}>{children}</Link>
);

export function NavbarTextMenuItem({
  name,
  ...props
}: LinkProps & {
  name: string;
  className: string;
}) {
  const {
    custom: { colors },
  } = useTheme();

  return (
    <NavLink {...props} activeStyle={{ color: colors.secondary }}>
      {name}
    </NavLink>
  );
}
