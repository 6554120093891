import * as React from "react";
import MuiTooltip from "@material-ui/core/Tooltip";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { defaultNavbarFontSize } from "../../components/Navbar/shared";
import { NAVBAR_Z_INDEX } from "@homewisedocs/components/lib/GenericNavbar";
import { SolutionsMegaMenuContent } from "./SolutionsMegaMenuContent";
import { SOLUTIONS } from "../../constants/routes";
import { NavLink } from "react-router-dom";

const TOOLTIP_TRANSITION_TIMEOUT_EXIT_MS = 250;

const useStyles = makeStyles(({ custom: { colors }, shadows }) =>
  createStyles({
    tooltip: {
      // Set maxWidth to override MUI's maxWidth: 300px
      maxWidth: "100%",
      backgroundColor: "white",
      // Use the same "elevation" as MUI's Menu component
      boxShadow: shadows[8],
    },
    popper: {
      zIndex: NAVBAR_Z_INDEX,
      // Adding paddingTop helps create a bridge between the 'Solutions' text and the tooltip menu so it stays
      // hovered when moving their cursor.
      paddingTop: "0.5rem",
    },
    solutionsText: {
      display: "block",
      padding: "1rem",
      color: "white",
      fontSize: defaultNavbarFontSize,
      textDecoration: "none",
    },
    activeSolutionsText: {
      color: colors.secondary,
    },
  })
);

export const SolutionsMegaMenu = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const closeMenu = () => setOpen(false);

  // Close the menu forcibly when the user clicks on a link in the menu. In
  // particular, temporarily set the tooltip's `interactive` flag to `false`.
  // Otherwise, the menu may annoyingly reopen if the user moves their cursor
  // immediately after clicking on a link in the menu.
  const [isInteractive, setInteractive] = React.useState(true);
  const hardCloseMenu = () => {
    setInteractive(false);
    closeMenu();

    // Re-enable interactivity once the previous "interactivy window" expires.
    // In practice, we need to wait _slightly_ longer than the tooltip's
    // actual `exit transition` value.
    window.setTimeout(() => {
      setInteractive(true);
    }, TOOLTIP_TRANSITION_TIMEOUT_EXIT_MS + 200);
  };

  // Hide menu when scrolling. Otherwise, the menu will stutter since it
  // will try to recalculate its position on the screen as the user scrolls.
  // Besides, this makes sense from a UX perspective. If user is scrolling,
  // they probably don't want to engage with mega menu anymore.
  React.useEffect(() => {
    window.addEventListener("scroll", hardCloseMenu);

    return () => window.removeEventListener("scroll", hardCloseMenu);
  }, []);

  return (
    <MuiTooltip
      open={open}
      title={<SolutionsMegaMenuContent onLinkItemClick={hardCloseMenu} />}
      onClose={closeMenu}
      onOpen={() => setOpen(true)}
      placement="bottom"
      interactive={isInteractive}
      classes={{
        tooltip: classes.tooltip,
        popper: classes.popper,
      }}
      TransitionProps={{
        timeout: {
          exit: TOOLTIP_TRANSITION_TIMEOUT_EXIT_MS,
        },
      }}
    >
      <div>
        {/* The MUI Tooltip has a hard time finding a ref for the NavLink
            (which is a function component), causing it not to show the tooltip
            when the link is hovered over. One solution is to wrap it in a span. */}
        <NavLink
          to={SOLUTIONS}
          onClick={hardCloseMenu}
          className={classNames(classes.solutionsText, {
            [classes.activeSolutionsText]: open,
          })}
          activeClassName={classes.activeSolutionsText}
        >
          Solutions
        </NavLink>
      </div>
    </MuiTooltip>
  );
};
