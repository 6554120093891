import { createStore, applyMiddleware, compose } from "redux";
import { FormStateMap } from "redux-form";
import createRavenMiddleware from "raven-for-redux";
import Raven from "raven-js";
import { rootReducer } from "../reducers";
import { EMIT_REDUX_STORE, WINDOW_REDUX_STORE_KEY } from "../constants/cypress";
import { State as SignUpState } from "../reducers/signupReducer";
import { State as DeveloperState } from "../reducers/developerReducer";
import { State as SearchResultsState } from "../reducers/searchResultsReducer";
import { State as TransactionTypeState } from "../reducers/transactionTypeReducer";
import { State as CartState } from "../reducers/cartReducer";
import { State as PaymentState } from "../reducers/paymentReducer";
import { State as DeliveryState } from "../reducers/deliveryReducer";
import { State as LsqState } from "../reducers/lenderSpecificQuestionnaireReducer";
import { State as AttachDocumentState } from "../reducers/attachDocumentReducer";
import { Action } from "../actions";
import { paymentFormName } from "../pageContent/Payment/formMetadata";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    [WINDOW_REDUX_STORE_KEY]: typeof store;
    Cypress?: {
      emit: (eventId: string, arg: any) => void;
    };
  }
}

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export interface StoreState {
  signup: SignUpState;
  searchResults: SearchResultsState;
  developer: DeveloperState;
  form: FormStateMap;
  transactionType: TransactionTypeState;
  attachDocument: AttachDocumentState;
  payment: PaymentState;
  delivery: DeliveryState;
  lenderSpecificQuestionnaire: LsqState;
  cart: CartState;
}

const ravenMiddleware = createRavenMiddleware(Raven, {
  stateTransformer: (state: StoreState | undefined) => {
    if (state == null) {
      return {};
    }

    return {
      ...state,
      // Redact payment form state.
      // [Nico 8/20/2018] I've already specified more sensitive fields
      // within Sentry so that we blacklist our redux-form fields that
      // are related to payment. That way, Sentry will ignore the sensitive
      // data in both our Redux actions and the store. But let's still redact
      // the payment info from the store to be safe.
      form: {
        ...state.form,
        [paymentFormName]: null,
      },
    };
  },
});

// Note on generics: We're using an enhancer, but it's just for the dev tools,
// so we shouldn't use it directly. As a result, we can use `void` types for the
// enhancer generics.
export const store = createStore<Partial<StoreState>, Action, void, void>(
  rootReducer,
  composeEnhancers(applyMiddleware(ravenMiddleware))
);

export type Store = typeof store;

// Check if we're running Cypress tests
if (typeof window !== "undefined" && window.Cypress) {
  // [Nico] Expose the Redux store on the browser's window object. This allows us to access the store
  // in Cypress tests, which is very helpful for debugging in Cypress. Technically, we can also use
  // this to manually call the store's `dispatch` method in our tests. But the event emitter approach
  // below is better for that use case.
  window[WINDOW_REDUX_STORE_KEY] = store;

  // [Nico] Emit an event so that we can seed the Redux store in our Cypress tests.
  // Note that this event is emitted synchronously, so we can attach an event listener before
  // our call to `cy.visit()`. Then, the callback will fire when the `emit` call is made below,
  // allowing us to call the store's `dispatch` method and seed the store before the app mounts.
  window.Cypress.emit(EMIT_REDUX_STORE, store);
}
