import { Action, ActionType } from "../actions";
import { UiTransactionType } from "../typings/transactionType";

type InitialState = Readonly<{
  leftSearchResultsPage: false;
  transactionType?: undefined;
}>;

type SetTransactionTypeState = Readonly<{
  leftSearchResultsPage: false;
  transactionType: UiTransactionType;
}>;

type LeftSearchResultsPageState = Readonly<{
  leftSearchResultsPage: true;
  transactionType: UiTransactionType;
}>;

export type State =
  | InitialState
  | SetTransactionTypeState
  | LeftSearchResultsPageState;

const INITIAL_STATE: State = {
  leftSearchResultsPage: false,
  transactionType: undefined,
};

export const transactionType = (
  state: State = INITIAL_STATE,
  action: Action
): State => {
  switch (action.type) {
    case ActionType.SET_TRANSACTION_TYPE:
      return {
        ...state,
        transactionType: action.payload,
      };
    case ActionType.SET_LEFT_SEARCH_RESULTS_PAGE:
      if (state.transactionType == null) {
        throw new Error(
          "The transaction type must not be undefined if leaving the search results page."
        );
      }
      return {
        ...state,
        transactionType: state.transactionType,
        leftSearchResultsPage: true,
      };
    default:
      return state;
  }
};
