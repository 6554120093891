import * as React from "react";
import { useHistory } from "react-router";
import { connect, DispatchProp } from "react-redux";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { UnreachableCaseError } from "@homewisedocs/common/lib/errors";
import { CookiesUnsupportedError } from "@homewisedocs/client-utils/lib/errors";
import { hasCookieSupport } from "@homewisedocs/client-utils/lib/hasCookieSupport";
import { useNavbarContext } from "@homewisedocs/components/lib/GenericNavbar/NavbarContext";
import { useRobots } from "@homewisedocs/components/lib/Robots";
import { useScrollRestoration } from "@homewisedocs/client-utils/lib/useScrollRestoration";
import { setZendeskHelpCenterSuggestions } from "@homewisedocs/client-utils/lib/zendesk";
import { useResponsive } from "@homewisedocs/components/lib/Responsive";
import { Footer } from "../Footer";
import { NAVBAR_HEIGHT } from "../Navbar/shared";
import { title as defaultTitle } from "../../constants/meta";
import { useAuth } from "../Auth";
import { PageWrapperDataTest } from "./dataTest";
import { StoreState } from "../../store";
import {
  getHoaUUID,
  getApiTransactionType,
  getExistingOrderInfo,
} from "../../selectors";
import { HOMEPAGE } from "../../constants/routes";
import { WithAuthRedirects } from "../../containers/withAuthRedirects";

const useStyles = makeStyles(
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
    mainContent: {
      paddingTop: "3rem",
      paddingBottom: "5rem",
      // In case the entire page's height would be less than the window's
      // height, the main content should stretch to fill the empty vertical space.
      // Note: The footer uses flex-grow=1 to avoid an IE 11 issue. Here we set
      // flex-grow to a much larger value of 10 so the main content will do the
      // vast majority of the stretching if the page is shorter than the window.
      flex: "10 0 auto",
      marginTop: NAVBAR_HEIGHT,
    },
    paddedSides: {
      paddingLeft: "5rem",
      paddingRight: "5rem",
      width: "calc(100% - 10rem)",
    },
    paddedSidesMobile: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      width: "calc(100% - 4rem)",
    },
  })
);

export enum BuiltUpState {
  NEW_ORDER_FLOW = "NEW_ORDER_FLOW",
  MODIFY_EXISTING_ORDER = "MODIFY_EXISTING_ORDER",
}

export interface Props {
  /**
   * The HTML document title. Uses a default title if the prop is not specified.
   */
  title?: string;

  /**
   * Renders the page contents
   */
  children?: React.ReactNode;

  /**
   * If true, the navbar will be transparent when the user is at the top of
   * the page. Defaults to false.
   */
  transparentNavbarAtTop?: boolean;

  /**
   * If true, this page requires authentication to use.
   * Defaults to true since most pages require auth.
   */
  requireAuth?: boolean;

  /**
   * Whether the user should be redirected to root if the order state has not
   * been initialized (e.g. in the event that the user has loaded an order flow
   * page without starting at the search results page)
   */
  requiresBuiltUpState?: BuiltUpState | null;

  /**
   * If true, this page will check if the user's browser has cookies enabled on
   * the site and divert them to an error page explaining that cookies are
   * required if cookies aren't enabled.
   * Defaults to false.
   */
  requiresCookieSupport?: boolean;

  /**
   * If specified, this string will be used to query the Zendesk Help Center. If
   * any articles are found, they'll be suggested in the chat widget.
   */
  helpCenterSearch?: string;

  /**
   * If true, adds a meta tag to the head that indicates search engines should
   * not index the page. Defaults to true in production if the page requires
   * authentication, and false if it doesn't; ignored in other environments,
   * where search engine indexing will always be discouraged.
   */
  searchEngineIndexingDiscouraged?: boolean;

  /**
   * If true, the sides of the page contents should have padding
   */
  padSides?: boolean;

  /**
   * CSS className. This will be applied to the wrapped page only (not to the footer)
   */
  className?: string;
}

type ViewProps = Props & ReduxStateProps & DispatchProp<StoreState>;

const PageWrapperView = ({
  padSides = true,
  title = defaultTitle,
  requireAuth = true,
  requiresBuiltUpState = null,
  requiresCookieSupport = false,
  helpCenterSearch,
  searchEngineIndexingDiscouraged = requireAuth,
  transparentNavbarAtTop = false,
  className,
  children,
  // The remaining individual props are unused and only pulled out to
  // prevent them from being spread into the container div's props
  hoaUUID,
  apiTransactionType,
  existingOrderInfo,
  dispatch,
  ...props
}: ViewProps) => {
  useScrollRestoration();

  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    if (helpCenterSearch) {
      setZendeskHelpCenterSuggestions({ search: helpCenterSearch });
    }
  }, [helpCenterSearch]);

  const { setTransparentAtTop } = useNavbarContext();
  React.useEffect(() => {
    setTransparentAtTop(transparentNavbarAtTop);
  }, [setTransparentAtTop, transparentNavbarAtTop]);

  const { discourageIndexing } = useRobots();
  React.useEffect(() => {
    if (searchEngineIndexingDiscouraged) {
      discourageIndexing();
    }
  }, [searchEngineIndexingDiscouraged, discourageIndexing]);

  const { authProps } = useAuth();

  const { mobile } = useResponsive();

  const meetsOrderStateRequirements = (): boolean => {
    if (requiresBuiltUpState === BuiltUpState.NEW_ORDER_FLOW) {
      return hoaUUID != null && apiTransactionType != null;
    } else if (requiresBuiltUpState === BuiltUpState.MODIFY_EXISTING_ORDER) {
      return existingOrderInfo != null;
    } else if (requiresBuiltUpState == null) {
      return true;
    }

    throw new UnreachableCaseError(requiresBuiltUpState);
  };
  React.useEffect(() => {
    if (!meetsOrderStateRequirements()) {
      history.replace(HOMEPAGE);
    }
  });

  React.useEffect(() => {
    if (requiresCookieSupport && !hasCookieSupport()) {
      throw new CookiesUnsupportedError();
    }
  }, [requiresCookieSupport]);

  if (requireAuth && !authProps.isAuthenticated) {
    return (
      <WithAuthRedirects>
        {({ redirectToLoginPage }) => {
          // Although the browser has navigated to the current page in a technical
          // sense, the user is being redirected away immediately - so don't
          // include this page in the browsing history. The user will be
          // surprised if they hit "back" and land on a page that they were
          // never really on - and they'll probably just be redirected back
          // to login again, putting them in an aggravating loop.
          redirectToLoginPage({
            includeCallingPageInHistory: false,
          });
          return null;
        }}
      </WithAuthRedirects>
    );
  }

  return (
    <div data-test={PageWrapperDataTest.root} className={classes.root}>
      <Helmet title={title} />
      {/* The main page content */}
      <main
        role="main"
        data-test={PageWrapperDataTest.content}
        className={classNames(
          classes.mainContent,
          {
            [classes.paddedSides]: !mobile && padSides,
            [classes.paddedSidesMobile]: mobile && padSides,
          },
          className
        )}
        {...props}
      >
        {meetsOrderStateRequirements() && children}
      </main>

      <Footer compact={requireAuth} />
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  hoaUUID: getHoaUUID(state),
  apiTransactionType: getApiTransactionType(state),
  existingOrderInfo: getExistingOrderInfo(state),
});

type ReduxStateProps = ReturnType<typeof mapStateToProps>;

export const PageWrapper = connect<ReduxStateProps, {}, Props>(mapStateToProps)(
  PageWrapperView
);
