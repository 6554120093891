import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core";
import { PageWrapper } from "../../components/PageWrapper";
import { ProgressiveHeroImage } from "@homewisedocs/components/lib/ProgressiveHeroImage";
import { H1, H4, HeaderColor } from "@homewisedocs/components/lib/Header";
import { PageNotFoundPageDataTest } from "./dataTest";
import BackgroundImage from "./images/astronaut.jpg";
import CompressedBackgroundImage from "./images/astronaut_compressed.jpg";
import { Button } from "@homewisedocs/components/lib/Button";
import { Link } from "react-router-dom";
import { ROOT } from "../../constants/routes";

const styles = ({ custom: { colors } }: Theme) =>
  createStyles({
    heroImage: {
      height: "70rem",
    },
    contentContainer: {
      textAlign: "left",
      margin: "8rem 3rem 0 3rem",
      maxWidth: "45rem",
    },
    header: {
      marginBottom: "2rem",
    },
    copy: {
      color: colors.white,
      lineHeight: 2,
    },
    backHomeButton: {
      marginTop: "15rem",
      marginBottom: "5rem",
    },
  });

export const PageNotFoundPage = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => (
    <PageWrapper requireAuth={false} transparentNavbarAtTop>
      <div data-test={PageNotFoundPageDataTest.root}>
        <ProgressiveHeroImage
          src={BackgroundImage}
          placeholder={CompressedBackgroundImage}
          className={classes.heroImage}
        />
        <div className={classes.contentContainer}>
          <H4 color={HeaderColor.secondary}>Error 404</H4>
          <H1 color={HeaderColor.white} className={classes.header}>
            Are you lost?
          </H1>
          <p className={classes.copy}>
            It appears that the destination you are trying to reach has moved or
            doesn't exist. Abort mission and come back to base.
          </p>
          <Button
            secondary
            component={Link}
            to={ROOT}
            className={classes.backHomeButton}
          >
            Back to home
          </Button>
        </div>
      </div>
    </PageWrapper>
  )
);
