import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { H2, HeaderColor } from "@homewisedocs/components/lib/Header";
import classNames from "classnames";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ custom: { colors }, breakpoints }: Theme) => {
  const mobileBreakpoint = breakpoints.down("sm");
  const desktopBreakpoint = breakpoints.up("md");

  return createStyles({
    wrapper: {
      [desktopBreakpoint]: {
        textAlign: "left",
        padding: "1rem 2rem",
      },
      [mobileBreakpoint]: {
        textAlign: "center",
        padding: "4rem 2rem",
      },
    },
    header: {
      [desktopBreakpoint]: {
        marginBottom: "3rem",
      },
      [mobileBreakpoint]: {
        marginBottom: "2rem",
      },
    },
    list: {
      listStyle: "none",
      lineHeight: 1.4,
      margin: "0",
      // Set padding 0 since ie11 was adding unwanted padding
      padding: "0",
      fontSize: "1.6rem",
    },
    listItem: {
      marginBottom: "2rem",
      [desktopBreakpoint]: {
        textAlign: "left",
      },
      [mobileBreakpoint]: {
        textAlign: "center",
      },
    },
    link: {
      textDecoration: "none",
      color: colors.secondaryDark,
    },
    lastListItem: {
      marginBottom: "0",
    },
  });
});

export interface Props {
  header: string;
  linkItems: Array<{
    label: string;
    path: string;
  }>;
  onLinkItemClick: () => void;
  className?: string;
}

export const LinkItems = ({
  header,
  linkItems,
  onLinkItemClick,
  className,
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.wrapper, className)} {...props}>
      <H2 color={HeaderColor.primary} className={classes.header}>
        {header}
      </H2>
      <nav>
        <ul className={classes.list}>
          {linkItems.map((item, index) => (
            <li
              key={index}
              className={classNames(classes.listItem, {
                [classes.lastListItem]: index + 1 === linkItems.length,
              })}
            >
              <Link
                to={item.path}
                onClick={onLinkItemClick}
                className={classes.link}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
