import without from "lodash/without";
import { Action, ActionType } from "../actions";

export type SelectedItemsState = Readonly<{
  selectedItems: Array<string>;
}>;

export type SelectedBundlesState = Readonly<{
  selectedBundles: Array<string>;
}>;

export type AcknowledgedState = Readonly<{
  acknowledgedFinalTransaction: boolean;
}>;

export type State = SelectedItemsState &
  SelectedBundlesState &
  AcknowledgedState;

const INITIAL_STATE: State = {
  selectedItems: [],
  selectedBundles: [],
  acknowledgedFinalTransaction: false,
};

export const cart = (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case ActionType.TOGGLE_INDIVIDUAL_ITEM:
      const itemId = action.payload;
      const { selectedItems } = state;
      const itemInCart: boolean = selectedItems.includes(itemId);

      return {
        ...state,
        selectedItems: itemInCart
          ? without(selectedItems, itemId)
          : [...selectedItems, itemId],
      };
    case ActionType.TOGGLE_BUNDLE:
      const bundleId = action.payload;
      const { selectedBundles } = state;
      const bundleInCart = selectedBundles.includes(bundleId);

      return {
        ...state,
        selectedBundles: bundleInCart
          ? without(state.selectedBundles, bundleId)
          : [...selectedBundles, bundleId],
      };
    case ActionType.CHECK_ACKNOWLEDGEMENT:
      return {
        ...state,
        acknowledgedFinalTransaction: true,
      };
    case ActionType.CLEAR_SELECTED_CART_ITEMS:
      return {
        ...state,
        selectedItems: [],
        selectedBundles: [],
      };
    default:
      return state;
  }
};
