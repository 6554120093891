import * as React from "react";
import Raven from "raven-js";
import { runZendesk } from "@homewisedocs/client-utils/lib/zendesk";
import { AuthProps } from "../Auth";

interface Props {
  authProps: AuthProps;
}

/**
 * Update the user's identity for monitoring tools
 */
export const AppIdentityTracking = ({ authProps }: Props) => {
  // Update various trackers when the user transitions from logged-in to
  // logged-out or vice versa
  React.useEffect(() => {
    if (authProps.isAuthenticated) {
      const user = authProps.user;

      Raven.setUserContext({
        // These first 3 fields (`id`, `username`, and `email`) are _not_ arbitrary. These are special keywords
        // that Sentry recognizes. We only really need 1 for Sentry to identify the user, but it makes it easier
        // to debug if we include all 3.
        id: user.uuid,
        username: user.username,
        email: user.email,
        // Sentry also accepts arbitrary user info. We can use whatever names we want for these keys
        orgId: user.orgId,
        orgName: user.orgName,
        orgType: user.orgType,
        fullName: user.fullName,
        isMasterRequestor: user.isMasterRequestor,
      });

      // Pre-fill fields in Zendesk's live chat form
      // https://developer.zendesk.com/embeddables/docs/widget/core#prefill
      runZendesk(zE => {
        zE("webWidget", "prefill", {
          name: {
            value: user.fullName,
          },
          email: {
            value: user.email,
          },
        });
      });
    } else {
      // Clear the user's context
      Raven.setUserContext();

      // Clear Zendesk data (like pre-filled form fields) on logout
      runZendesk(zE => {
        zE("webWidget", "logout");
      });
    }
  }, [authProps.isAuthenticated]);

  return null;
};
