import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Roles } from "./Roles";
import { Industries } from "./Industries";
import { Props as LinkItemsProps } from "./shared/LinkItems";
import { SolutionsMenuDataTest } from "./DataTest";

const useStyles = makeStyles(
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      textAlign: "left",
      padding: "3rem 0 3rem 3rem",
      // Note: Need to set width and cannot use percentage.
      // Popper.js (used internally by MUI) would otherwise have
      // trouble calculating the correct position.
      width: "52rem",
    },
  })
);

export const SolutionsMegaMenuContent = ({
  onLinkItemClick,
}: Pick<LinkItemsProps, "onLinkItemClick">) => {
  const classes = useStyles();

  return (
    <div
      className={classes.wrapper}
      data-test={SolutionsMenuDataTest.solutionsMenu}
    >
      <Roles onLinkItemClick={onLinkItemClick} />
      <Industries onLinkItemClick={onLinkItemClick} />
    </div>
  );
};
