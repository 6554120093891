import { StoreState } from "../store";
import {
  apiTransactionType,
  isResaleTransaction,
  isRefiTransaction,
} from "../utils/transactionType";
import {
  TransactionType as ApiTransactionType,
  ShippingMethod,
} from "../../src/__generated__/gql";
import { getFormValues } from "redux-form";
import { deserializeReduxDate } from "./utils";
import {
  deliveryFormName,
  DeliveryFormValues,
  STANDARD_PROCESSING_RADIO_VALUE,
} from "../pageContent/Delivery/formMetadata";
import {
  transactionDetailsFormName,
  TransactionDetailsFormValues,
  BuyerOccupiedUiValue,
  BuyerFormValues,
} from "../pageContent/TransactionDetails/formMetadata";
import {
  LenderFormTypeValues,
  lsqFormName,
  LsqFormValues,
  lsqBorrowerInfoSectionName,
  lsqLenderInfoSectionName,
} from "../pageContent/LSQ/formMetadata";
import {
  CancelOrderFormName,
  CancelOrderModalFormValues,
} from "../pageContent/MyOrders/Modals/CancelOrderModal/formMetadata";
import {
  PaymentFormValues,
  ECheckFormValues,
  paymentFormName,
  PayNowValue,
} from "../pageContent/Payment/formMetadata";
import {
  EstimatedClosingDateFormValues,
  requestUpdateFormName,
} from "../pageContent/MyOrders/Modals/RequestUpdateModal/formMetadata";
import { ProfileFormValues } from "../components/UserProfileForm/formMetadata";
import { signUpFormName } from "../pageContent/SignUp/formMetadata";
import { UserType } from "../components/UserProfileForm";
import {
  propertyAddressFormName,
  PropertyAddressFormValues,
} from "../pageContent/SearchResults/AddressVerification/formMetadata";
import { CurrentOwnerAddressVerificationStatus } from "../__generated__/gql";
import { notNullish } from "@homewisedocs/common/lib/utils/notNullish";

// Sign Up Selectors
export const getFirstMyOrdersVisitAfterSignUp = (state: StoreState) =>
  state.signup.firstMyOrdersVisitAfterSignUp;

// Search Results Selectors
export const getSearchResultsState = (state: StoreState) => state.searchResults;
export const getHoaUUID = (state: StoreState) =>
  getSearchResultsState(state).hoaUUID;
export const getHoaUnitUUID = (state: StoreState) =>
  getSearchResultsState(state).hoaUnitUUID;
export const getSearchByType = (state: StoreState) =>
  getSearchResultsState(state).searchBy;
export const getAddressVerificationStatus = (
  state: StoreState
): CurrentOwnerAddressVerificationStatus | undefined => {
  const searchResultsState = getSearchResultsState(state);
  return searchResultsState.searchBy === undefined
    ? undefined
    : searchResultsState.addressVerificationStatus;
};
export const getConfirmedOwnerPropertyAddress = (state: StoreState) => {
  const searchResultsState = getSearchResultsState(state);
  return searchResultsState.searchBy === undefined
    ? undefined
    : searchResultsState.address;
};

// Subject property address entered by the user
const getPropertyAddressFormValues = getFormValues<PropertyAddressFormValues>(
  propertyAddressFormName
);

export const getUserSuppliedOwnerProperty = (
  state: StoreState
): PropertyAddressFormValues => ({
  streetAddress: getPropertyAddressFormValues(state)?.streetAddress,
  unit: getPropertyAddressFormValues(state)?.unit,
  city: getPropertyAddressFormValues(state)?.city,
  state: getPropertyAddressFormValues(state)?.state,
  zip: getPropertyAddressFormValues(state)?.zip,
});

export type PropertyAddressState = ReturnType<
  typeof getUserSuppliedOwnerProperty
>;

// Transaction Type Selectors

/**
 * Get the transaction type used for displaying the transaction type in the UI.
 * This transaction type differentiates between resale and "resale on behalf of".
 */
export const getTransactionTypeState = (state: StoreState) =>
  state.transactionType;

export const getUiTransactionType = (state: StoreState) =>
  state.transactionType.transactionType;

export const getLeftSearchResultsPage = (state: StoreState) =>
  getTransactionTypeState(state).leftSearchResultsPage;

/**
 * `getApiTransactionType` is most likely what you'll want to use if you need to send a GraphQL query that
 * involves a transaction type. This transaction type does NOT differentiate between resale and "resale on behalf of".
 * This is useful for making API requests, because the backend doesn't care about resale vs resale on behalf of.
 * It just wants to know if it's a resale, refi, or "other" transaction.
 */
export const getApiTransactionType = (
  state: StoreState
): ApiTransactionType | undefined =>
  apiTransactionType(getUiTransactionType(state));

export const getIsResaleTransaction = (state: StoreState) =>
  isResaleTransaction(getUiTransactionType(state));
export const getIsRefiTransaction = (state: StoreState) =>
  isRefiTransaction(getUiTransactionType(state));

// Developer Selectors
export const getDeveloperState = (state: StoreState) => state.developer;
export const getDeveloperId = (state: StoreState) =>
  getDeveloperState(state).developerId;
export const getNewConstruction = (state: StoreState) =>
  getDeveloperState(state).newConstruction;
export const getLeftResaleTransactionPage = (state: StoreState) =>
  getDeveloperState(state).leftResaleTransactionPage;

// Cart Selectors
export const getCart = (state: StoreState) => state.cart;
export const getIndividualItemsInCart = (state: StoreState) =>
  getCart(state).selectedItems;
export const getBundlesInCart = (state: StoreState) =>
  getCart(state).selectedBundles;
export const getAcknowledgedTransaction = (state: StoreState) =>
  getCart(state).acknowledgedFinalTransaction;

// Lender-specific questionnaire selectors
export const getIsQnrLenderSpecific = (state: StoreState): boolean =>
  getLsqFormType(state) === LenderFormTypeValues.LSQ;
export const getLsqId = (state: StoreState) =>
  state.lenderSpecificQuestionnaire.lsqId;
export const getLsqHasNewFormUpload = (state: StoreState) =>
  state.lenderSpecificQuestionnaire.newFormUpload;
export const getLsqUploadedForm = (state: StoreState) =>
  state.lenderSpecificQuestionnaire.uploadedForm;
export const getRecordExtraLsqInfo = (state: StoreState) =>
  state.lenderSpecificQuestionnaire.recordExtraLsqInfo;
export const getLsqUploadedFormId = (state: StoreState) =>
  getLsqUploadedForm(state)?.id;
const getLsqFormValues = getFormValues<LsqFormValues>(lsqFormName);
export const getLsqBorrowerInfo = (state: StoreState) =>
  getLsqFormValues(state)?.[lsqBorrowerInfoSectionName];
export const getLsqLenderInfo = (state: StoreState) =>
  getLsqFormValues(state)?.[lsqLenderInfoSectionName];
export const getLsqFormType = (state: StoreState) =>
  getLsqFormValues(state)?.lenderFormType;
export const getLsqFormLender = (state: StoreState) =>
  getLsqFormValues(state)?.lenderFormLender;
export const getLsqAcknowledgment = (state: StoreState) =>
  getLsqFormValues(state)?.lsqAcknowledgment;
export const getStandardQnrAcknowledgment = (state: StoreState) =>
  getLsqFormValues(state)?.standardQnrAcknowledgment;

// Payment Selectors
export const getPaymentState = (state: StoreState) => state.payment;

export const getPaymentMethod = (state: StoreState) =>
  getPaymentState(state).paymentMethod;

export const getExistingOrderInfo = (state: StoreState) =>
  getPaymentState(state).existingOrder;

export const getRequestingOrderUpdate = (state: StoreState): boolean =>
  getExistingOrderInfo(state) != null;

// Payment Form Data
export const getPaymentFormValues =
  getFormValues<PaymentFormValues>(paymentFormName);

export const getRawPayNowValue = (state: StoreState): PayNowValue | undefined =>
  getPaymentFormValues(state)?.payNow as PayNowValue | undefined;

export const getPayNow = (state: StoreState): boolean =>
  getRawPayNowValue(state) === PayNowValue.YES;

export const getCreditCardExpMonth = (state: StoreState) =>
  getPaymentFormValues(state)?.ccExpMonth;
export const getCreditCardExpYear = (state: StoreState) =>
  getPaymentFormValues(state)?.ccExpYear;
export const getCreditCardFirstName = (state: StoreState) =>
  getPaymentFormValues(state)?.ccFirstName;
export const getCreditCardLastName = (state: StoreState) =>
  getPaymentFormValues(state)?.ccLastName;
export const getCreditCardNumber = (state: StoreState) =>
  getPaymentFormValues(state)?.ccNumber;
export const getCreditCardCVV = (state: StoreState) =>
  getPaymentFormValues(state)?.ccCVV;

export const getCreditCardBillingFirstName = (state: StoreState) =>
  getPaymentFormValues(state)?.ccFirstName;
export const getCreditCardBillingLastName = (state: StoreState) =>
  getPaymentFormValues(state)?.ccLastName;
export const getCreditCardBillingStreetAddress = (state: StoreState) =>
  getPaymentFormValues(state)?.street;
export const getCreditCardBillingCity = (state: StoreState) =>
  getPaymentFormValues(state)?.city;
export const getCreditCardBillingState = (state: StoreState) =>
  getPaymentFormValues(state)?.state;
export const getCreditCardBillingZip = (state: StoreState) =>
  getPaymentFormValues(state)?.zip;
export const getCreditCardBillingCountry = (state: StoreState) =>
  getPaymentFormValues(state)?.country;
export const getCreditCardBillingCardHolderEmail = (state: StoreState) =>
  getPaymentFormValues(state)?.cardHolderEmail;

export const getCreditCardInfo = (state: StoreState) => ({
  cardNumber: getCreditCardNumber(state),
  cvv: getCreditCardCVV(state),
  expMonth: getCreditCardExpMonth(state),
  expYear: getCreditCardExpYear(state),
  billingInfo: {
    firstName: getCreditCardBillingFirstName(state),
    lastName: getCreditCardBillingLastName(state),
    streetAddress: getCreditCardBillingStreetAddress(state),
    city: getCreditCardBillingCity(state),
    state: getCreditCardBillingState(state),
    zip: getCreditCardBillingZip(state),
    country: getCreditCardBillingCountry(state),
    cardHolderEmail: getCreditCardBillingCardHolderEmail(state),
  },
});

const getECheckFormValues = getFormValues<ECheckFormValues>(paymentFormName);

export const getECheckFirstName = (state: StoreState) =>
  getECheckFormValues(state)?.firstName;
export const getECheckLastName = (state: StoreState) =>
  getECheckFormValues(state)?.lastName;
export const getECheckBankAccountNumber = (state: StoreState) =>
  getECheckFormValues(state)?.bankAccountNumber;
export const getECheckBankRoutingNumber = (state: StoreState) =>
  getECheckFormValues(state)?.bankRoutingNumber;

export const getECheckInfo = (state: StoreState) => ({
  firstName: getECheckFirstName(state),
  lastName: getECheckLastName(state),
  bankAccountNumber: getECheckBankAccountNumber(state),
  bankRoutingNumber: getECheckBankRoutingNumber(state),
});

// Transaction Form Data
const getTransactionDetailsFormValues =
  getFormValues<TransactionDetailsFormValues>(transactionDetailsFormName);

const getCurrentOwnerFormValues = (state: StoreState) =>
  getTransactionDetailsFormValues(state)?.currentOwner;

export const getCurrentOwnerFirstName = (state: StoreState) =>
  getCurrentOwnerFormValues(state)?.firstName;
export const getCurrentOwnerLastName = (state: StoreState) =>
  getCurrentOwnerFormValues(state)?.lastName;
export const getCurrentOwnerPhone = (state: StoreState) =>
  getCurrentOwnerFormValues(state)?.phone;
export const getCurrentOwnerEmail = (state: StoreState) =>
  getCurrentOwnerFormValues(state)?.email;
export const getCurrentOwnerStreetAddress = (state: StoreState) =>
  getCurrentOwnerFormValues(state)?.streetAddress;
export const getCurrentOwnerUnit = (state: StoreState) =>
  getCurrentOwnerFormValues(state)?.unit;
export const getCurrentOwnerCity = (state: StoreState) =>
  getCurrentOwnerFormValues(state)?.city;
export const getCurrentOwnerState = (state: StoreState) =>
  getCurrentOwnerFormValues(state)?.state;
export const getCurrentOwnerZip = (state: StoreState) =>
  getCurrentOwnerFormValues(state)?.zip;

export const getCurrentOwner = (state: StoreState) => ({
  firstName: getCurrentOwnerFirstName(state),
  lastName: getCurrentOwnerLastName(state),
  phone: getCurrentOwnerPhone(state),
  email: getCurrentOwnerEmail(state),
  // [Pete 6/12/2019] These address fields are used by the Modify Order mutation
  // but _not_ the Place Order mutation, which will ignore these in favor of the
  // address from the form on the Search Results page.
  streetAddress: getCurrentOwnerStreetAddress(state),
  unit: getCurrentOwnerUnit(state),
  city: getCurrentOwnerCity(state),
  state: getCurrentOwnerState(state),
  zip: getCurrentOwnerZip(state),
});

export type CurrentOwnerState = ReturnType<typeof getCurrentOwner>;

export const getBuyerFormValues = (
  state: StoreState
): BuyerFormValues | undefined => getTransactionDetailsFormValues(state)?.buyer;

export const getBuyerEmail = (state: StoreState) =>
  getBuyerFormValues(state)?.email;
export const getBuyerCountry = (state: StoreState) =>
  getBuyerFormValues(state)?.country;

export const getTransactionInformation = (state: StoreState) =>
  getTransactionDetailsFormValues(state)?.transactionInformation;

const getBuyerIntendsMoveIntoSubjectAddress = (state: StoreState) =>
  getTransactionInformation(state)?.buyerIntendsToMoveIn;

export const getBuyerOccupied = (state: StoreState): boolean | null => {
  const buyerOccupiedValue = getBuyerIntendsMoveIntoSubjectAddress(state);

  if (buyerOccupiedValue === BuyerOccupiedUiValue.YES) {
    return true;
  } else if (buyerOccupiedValue === BuyerOccupiedUiValue.NO) {
    return false;
  } else {
    return null;
  }
};

export const getLoanNumber = (state: StoreState) =>
  getTransactionInformation(state)?.loanNumber;

export const getResaleFormOptions = (state: StoreState) =>
  getTransactionInformation(state)?.resaleFormOptions;

// Delivery Selectors
export const getDelivery = (state: StoreState) => state.delivery;
export const getNumShareOrderEmails = (state: StoreState) =>
  getDelivery(state).numShareOrderEmails;
export const getSendHardCopy = (state: StoreState) =>
  getDelivery(state).sendHardCopy;
export const getSendTextNotification = (state: StoreState) =>
  getDelivery(state).sendTextNotification;
export const getOrderAlertDismissed = (state: StoreState) =>
  getDelivery(state).orderAlertDismissed;
export const getSpecialOrderAlertDismissed = (state: StoreState) =>
  getDelivery(state).specialOrderAlertDismissed;

const getDeliveryFormValues =
  getFormValues<DeliveryFormValues>(deliveryFormName);

export const getEstimatedClosingDate = (state: StoreState) =>
  deserializeReduxDate(getDeliveryFormValues(state)?.estimatedClosingDate);

// Processing Selectors
export const getProcessingOption = (state: StoreState) =>
  getDeliveryFormValues(state)?.processingOption;
export const getStandardProcessing = (state: StoreState) =>
  getProcessingOption(state) === STANDARD_PROCESSING_RADIO_VALUE;
export const getRushId = (state: StoreState) =>
  getStandardProcessing(state) ? undefined : getProcessingOption(state);

export const getTextNotificationPhoneNumber = (state: StoreState) =>
  getDeliveryFormValues(state)?.textNotificationPhoneNumber;
export const getHardCopyShippingMethod = (
  state: StoreState
): ShippingMethod | undefined =>
  getDeliveryFormValues(state)?.hardCopyShippingMethod as
    | ShippingMethod
    | undefined;
export const getHardCopyShippingCountry = (state: StoreState) =>
  getDeliveryFormValues(state)?.hardCopyCountry;

export const getHardCopyShippingFormState = (state: StoreState) => {
  const deliveryFormValues = getDeliveryFormValues(state);
  return {
    name: deliveryFormValues?.hardCopyName,
    streetAddress: deliveryFormValues?.hardCopyAddress,
    unit: deliveryFormValues?.hardCopyUnit,
    city: deliveryFormValues?.hardCopyCity,
    state: deliveryFormValues?.hardCopyState,
    postalCode: deliveryFormValues?.hardCopyZip,
    country: getHardCopyShippingCountry(state),
    shippingMethod: getHardCopyShippingMethod(state),
  };
};

export type ShippingInfoFormState = ReturnType<
  typeof getHardCopyShippingFormState
>;

export const getShareOrderEmails = (state: StoreState) => {
  const deliveryFormValues = getDeliveryFormValues(state);
  if (deliveryFormValues) {
    return Object.values(deliveryFormValues.shareOrderEmails).filter(
      notNullish
    );
  }
  return [];
};

// Cancel Order Selector
const getCancelOrderFormValues =
  getFormValues<CancelOrderModalFormValues>(CancelOrderFormName);

export const getCancellationReason = (state: StoreState) =>
  getCancelOrderFormValues(state)?.cancellationReason;
export const getCancellationComments = (state: StoreState) =>
  getCancelOrderFormValues(state)?.comments;
export const getCancelOrderInfo = (state: StoreState) => ({
  reason: getCancellationReason(state),
  comments: getCancellationComments(state),
});

// Attach Document Selectors
export const getAttachDocumentState = (state: StoreState) =>
  state.attachDocument;
export const getSelectedDocument = (state: StoreState) =>
  getAttachDocumentState(state).selectedDocument;
export const getSelectedDocumentType = (state: StoreState) =>
  getAttachDocumentState(state).selectedDocumentType;

// Request Order Update Selector
const getRequestUpdateFormValues =
  getFormValues<EstimatedClosingDateFormValues>(requestUpdateFormName);

export const getRequestUpdateEstimatedClosingDate = (state: StoreState) =>
  deserializeReduxDate(getRequestUpdateFormValues(state)?.estimatedClosingDate);

// Sign-up form selectors
const getSignUpFormValues = (state: StoreState) =>
  getFormValues<ProfileFormValues>(signUpFormName)(state);

export const getSignUpUserType = (state: StoreState): UserType | undefined =>
  getSignUpFormValues(state)?.userType as UserType | undefined;
