import { ActionType, Action } from "../actions";

type InitialState = Readonly<{
  newConstruction?: undefined;
  developerId?: undefined;
  leftResaleTransactionPage: false;
}>;

type ExistingHomeState = Readonly<{
  newConstruction: false;
  // If it's not a new home, it should not be possible to have a developer selected
  developerId?: undefined;
  leftResaleTransactionPage: false;
}>;

type ExistingHomeLeftResaleTransactionPageState = Readonly<{
  newConstruction: false;
  developerId?: undefined;
  leftResaleTransactionPage: true;
}>;

type NewHomeState = Readonly<{
  newConstruction: true;
  // If it's a new home, then there may be a developer selected
  developerId?: string | undefined;
  leftResaleTransactionPage: false;
}>;

type NewHomeLeftResaleTransactionPage = Readonly<{
  newConstruction: true;
  developerId: string;
  leftResaleTransactionPage: true;
}>;

export type State =
  | InitialState
  | ExistingHomeState
  | NewHomeState
  | ExistingHomeLeftResaleTransactionPageState
  | NewHomeLeftResaleTransactionPage;

const INITIAL_STATE: State = {
  developerId: undefined,
  newConstruction: undefined,
  leftResaleTransactionPage: false,
};

export const developer = (
  state: State = INITIAL_STATE,
  action: Action
): State => {
  switch (action.type) {
    case ActionType.SET_NEW_CONSTRUCTION:
      if (action.payload) {
        return {
          ...state,
          newConstruction: true,
          leftResaleTransactionPage: false,
        };
      }
      return {
        ...state,
        newConstruction: false,
        developerId: undefined,
        leftResaleTransactionPage: false,
      };
    case ActionType.SET_DEVELOPER:
      return {
        ...state,
        newConstruction: true,
        developerId: action.payload,
      };
    case ActionType.SET_LEFT_RESALE_TRANACTION_PAGE:
      if (state.newConstruction == null) {
        throw new Error(
          "The newConstruction must not be undefined if leaving the resale transaction page."
        );
      }
      if (state.newConstruction) {
        if (state.developerId == null) {
          throw new Error(
            "The developerId must not be undefined if newConstruction is true and leaving the resale transaction page."
          );
        }
        return {
          ...state,
          newConstruction: true,
          developerId: state.developerId,
          leftResaleTransactionPage: true,
        };
      } else {
        return {
          ...state,
          newConstruction: false,
          leftResaleTransactionPage: true,
        };
      }

    default:
      return state;
  }
};
