import { FormValues } from "../../../../utils/reduxForm";
import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";

type FormSectionName = "estimatedClosingDate";

export const FormSectionNames: FormFieldNameMap<FormSectionName> = {
  estimatedClosingDate: "estimatedClosingDate",
};

export type EstimatedClosingDateFormValues = FormValues<FormSectionName>;

export const requestUpdateFormName = "RequestUpdate";
