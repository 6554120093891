import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";
import { FormValues } from "../../../utils/reduxForm";

export const propertyAddressFormName = "PropertyAddress";

type FormFieldNames = "streetAddress" | "unit" | "city" | "state" | "zip";

export const PropertyAddressFormFieldNames: FormFieldNameMap<FormFieldNames> = {
  streetAddress: "streetAddress",
  unit: "unit",
  city: "city",
  state: "state",
  zip: "zip",
};

export type PropertyAddressFormValues = FormValues<FormFieldNames>;
