import { enforceNonNullable } from "@homewisedocs/common/lib/utils/enforceNonNullable";

type RecaptchaToken = string;

interface GRecaptcha {
  ready: (callback: Function) => void;
  execute: (
    siteKey: string,
    { action }: { action: string }
  ) => Promise<RecaptchaToken>;
}

declare global {
  interface Window {
    grecaptcha: GRecaptcha | undefined;
  }
}

export const trackRecaptchaAction = (
  action: RecaptchaAction
): Promise<RecaptchaToken | null> =>
  new Promise((resolve, reject) => {
    const RECAPTCHA_V3_SITE_KEY = enforceNonNullable(
      process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY,
      "Expected REACT_APP_RECAPTCHA_V3_SITE_KEY environment variable to be defined"
    );

    const grecaptcha = window.grecaptcha;

    if (!grecaptcha) {
      // This app no longer loads the Recaptcha script, since it was
      // determined to be causing the issues described in
      // https://github.com/homewisedocs/homewisedocs/pull/2896
      // (possibly by changing the global WeakMap implementation in ways that
      // caused LogRocket and the Apollo client to fail). Given that, we should
      // expect `grecaptcha` to be undefined. We can resolve to a `null` value
      // for now instead of rejecting - rejecting this Promise would cause a
      // deluge of Sentry reports to be sent by the various callers of this
      // function.
      // TODO: remove `null` from the type of this function's return value if we
      // start loading Recaptcha again.
      resolve(null);
      return;
    }

    grecaptcha &&
      grecaptcha.ready(() => {
        grecaptcha
          .execute(RECAPTCHA_V3_SITE_KEY, { action })
          .then(resolve, reject);
      });
  });

export enum RecaptchaAction {
  ATTEMPT_LOGIN = "AttemptLogin",
  ATTEMPT_EXTEND_SESSION = "AttemptExtendSession",
  ATTEMPT_PLACE_ORDER = "AttemptPlaceOrder",
  VISIT_HOMEPAGE = "VisitHomepage",
  VISIT_MY_ORDERS = "VisitMyOrders",
}
