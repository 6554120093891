import { US_COUNTRY_CODE } from "@homewisedocs/common/lib/constants/geo";
import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";
import { FormValues } from "../../utils/reduxForm";
import { TransactionFormSectionValues } from "./TransactionFormSection/TransactionFormSection";

type TransactionDetailsFormFieldName =
  | "buyer"
  | "currentOwner"
  | "transactionInformation"
  | "resaleFormOptions";

export const transactionDetailsFormFieldNames: FormFieldNameMap<TransactionDetailsFormFieldName> =
  {
    currentOwner: "currentOwner",
    buyer: "buyer",
    transactionInformation: "transactionInformation",
    resaleFormOptions: "resaleFormOptions",
  };

type TransactionInfoFormFieldNames = "buyerIntendsToMoveIn" | "loanNumber";

export const TransactionInfoFieldNames: FormFieldNameMap<TransactionInfoFormFieldNames> =
  {
    buyerIntendsToMoveIn: "buyerIntendsToMoveIn",
    loanNumber: "loanNumber",
  };

export type TransactionInfoFormValues =
  FormValues<TransactionInfoFormFieldNames> & {
    [transactionDetailsFormFieldNames.resaleFormOptions]?: ResaleFormOptionsFormValues;
  };

// The names of the resale form option fields are dynamic since we retrieve them
// from the API, so we can't type them explicitly.
export type ResaleFormOptionsFormValues = Record<string, string | undefined>;

export function resaleFormOptionToReduxFormName(id: string): string {
  // Resale form option IDs are currently stringified integers. Simply passing
  // the ID as the field name would cause it be interpreted as an array index by
  // `redux-form`, causing all indices prior to it to be initialized to `null`.
  // We can add a prefix to the ID, which prevents `redux-form` from
  // interpreting the field name as a number, to avoid that.
  // See https://github.com/erikras/redux-form/issues/3431
  return `id:${id}`;
}

export function acknowledgmentToReduxFormName(id: string): string {
  return `acknowledgement:${id}`;
}

export type CurrentOwnerFormValues = Omit<
  TransactionFormSectionValues,
  "country"
>;
export type BuyerFormValues = TransactionFormSectionValues;
export interface TransactionDetailsFormValues {
  [transactionDetailsFormFieldNames.currentOwner]: CurrentOwnerFormValues;
  // The buyer form will not be available for refinance transactions
  [transactionDetailsFormFieldNames.buyer]?: BuyerFormValues | undefined;
  [transactionDetailsFormFieldNames.transactionInformation]?: TransactionInfoFormValues;
}

export const transactionDetailsFormName = "TransactionDetails";

export enum BuyerOccupiedUiValue {
  YES = "yes",
  NO = "no",
}

export const INITIAL_BUYER_COUNTRY_CODE = US_COUNTRY_CODE;
