import { State as SearchResultsState } from "../reducers/searchResultsReducer";
import { UiTransactionType } from "../typings/transactionType";
import { ActionsUnion, createAction } from "./helpers";
import {
  PaymentMethod,
  OrderAttachmentDocumentType,
} from "../__generated__/gql";
import { Address } from "../typings/address";
import { CurrentOwnerAddressVerificationStatus } from "../__generated__/gql";
import { ExistingOrderInfo } from "../reducers/paymentReducer";

export enum ActionType {
  RESET_STORE = "[ActionType] reset store",
  RESET_STORE_FROM_SEARCH_RESULTS_PAGE = "[ActionType] reset from search results page",
  RESET_STORE_FROM_RESALE_TRANSACTION_PAGE = "[ActionType] reset from resale transaction page",
  RESET_STORE_FROM_MY_ORDERS_PAGE = "[ActionType] reset from my orders page",
  SET_FIRST_MY_ORDERS_VISIT_AFTER_SIGN_UP = "[ActionType] set first my orders visit after sign up",
  SET_TRANSACTION_TYPE = "[ActionType] set transaction type",
  SET_SEARCH_RESULTS = "[ActionType] set search results",
  SET_HOA_UUID = "[ActionType] set hoa UUID",
  SET_HOA_PROPERTY_ADDRESS = "[ActionType] set hoa property address",
  SET_NEW_CONSTRUCTION = "[ActionType] set new construction",
  SET_DEVELOPER = "[ActionType] set developer",
  TOGGLE_INDIVIDUAL_ITEM = "[ActionType] toggle individual item",
  TOGGLE_BUNDLE = "[ActionType] toggle bundle",
  CLEAR_SELECTED_CART_ITEMS = "[ActionType] clear selected cart items",
  CHECK_ACKNOWLEDGEMENT = "[ActionType] check acknowledgement",
  CLEAR_LSQ_SELECTION = "[ActionType] Clear lender-specific questionnaire selection",
  SELECT_LSQ_WITH_ID = "[ActionType] set lender-specific questionnaire with ID",
  SELECT_MY_FORM_IS_NOT_HERE = "[ActionType] select my form is not here",
  SET_NEW_LSQ_FORM = "[ActionType] set new lsq form",
  TOGGLE_RECORD_EXTRA_LSQ_INFO = "[ActionType] toggle record extra LSQ info",
  TOGGLE_SEND_HARD_COPY = "[ActionType] toggle send hard copy",
  TOGGLE_SEND_TEXT_NOTIFICATION = "[ActionType] toggle send text notification",
  ADD_SHARE_ORDER_EMAIL = "[ActionType] add share order email",
  DISMISS_ORDER_ALERT = "[ActionType] dismiss order alert",
  DISMISS_SPECIAL_ORDER_ALERT = "[ActionType] dismiss special order alert",
  SET_PAYMENT_METHOD = "[ActionType] set payment method",
  SET_LEFT_SEARCH_RESULTS_PAGE = "[ActionType] set left search results page",
  SET_LEFT_RESALE_TRANACTION_PAGE = "[ActionType] set left resale transaction page",
  SET_EXISTING_ORDER_INFO = "[ActionType] set info about existing order",
  STOP_UPDATING_EXISTING_ORDER = "[ActionType] stop updating existing order",
  SET_SELECTED_DOCUMENT = "[ActionType] set uploaded document",
  SET_SELECTED_DOCUMENT_TYPE = "[ActionType] set uploaded document type",
  CLEAR_SELECTED_DOCUMENT = "[ActionType] clear uploaded document and document type",
}

export const Actions = {
  resetStore: () => createAction(ActionType.RESET_STORE),

  resetStoreFromSearchResultsPage: () =>
    createAction(ActionType.RESET_STORE_FROM_SEARCH_RESULTS_PAGE),

  resetStoreFromResaleTransactionPage: () =>
    createAction(ActionType.RESET_STORE_FROM_RESALE_TRANSACTION_PAGE),

  resetStoreFromMyOrdersPage: () =>
    createAction(ActionType.RESET_STORE_FROM_MY_ORDERS_PAGE),

  setFirstMyOrdersVisitAfterSignUp: (payload: boolean) =>
    createAction(ActionType.SET_FIRST_MY_ORDERS_VISIT_AFTER_SIGN_UP, payload),

  setSearchResults: (payload: SearchResultsState) =>
    createAction(ActionType.SET_SEARCH_RESULTS, payload),

  setHoaUUID: (hoaUUID: string) =>
    createAction(ActionType.SET_HOA_UUID, hoaUUID),

  setHoaPropertyAddress: (
    payload:
      | {
          address: Address;
          addressVerificationStatus: CurrentOwnerAddressVerificationStatus;
        }
      | {
          address: undefined;
          addressVerificationStatus: undefined;
        }
  ) => createAction(ActionType.SET_HOA_PROPERTY_ADDRESS, payload),

  setTransactionType: (transactionType: UiTransactionType) =>
    createAction(ActionType.SET_TRANSACTION_TYPE, transactionType),

  setNewConstruction: (newConstruction: boolean) =>
    createAction(ActionType.SET_NEW_CONSTRUCTION, newConstruction),

  setDeveloper: (developerId: string) =>
    createAction(ActionType.SET_DEVELOPER, developerId),

  toggleIndividualItem: (itemId: string) =>
    createAction(ActionType.TOGGLE_INDIVIDUAL_ITEM, itemId),

  toggleBundle: (bundleId: string) =>
    createAction(ActionType.TOGGLE_BUNDLE, bundleId),

  clearSelectedCartItems: () =>
    createAction(ActionType.CLEAR_SELECTED_CART_ITEMS),

  checkAcknowledgement: () => createAction(ActionType.CHECK_ACKNOWLEDGEMENT),

  /**
   * Clear the specific form selection, whether it's an existing form with an ID or a new form.
   * Note: If the user has already uploaded a form, this will *not* clear out the state
   * corresponding to the upload. It will simply unselect the radio option.
   */
  clearLsqSelection: () => createAction(ActionType.CLEAR_LSQ_SELECTION),

  /**
   * Select a particular lender-specific questionnaire by its ID.
   */
  selectLsqWithId: (lsqId: string) =>
    createAction(ActionType.SELECT_LSQ_WITH_ID, lsqId),

  selectMyFormIsNotHere: () =>
    createAction(ActionType.SELECT_MY_FORM_IS_NOT_HERE),

  setNewLsqForm: ({ id, filename }: { id: string; filename: string }) =>
    createAction(ActionType.SET_NEW_LSQ_FORM, { id, filename }),

  toggleRecordExtraLsqInfo: () =>
    createAction(ActionType.TOGGLE_RECORD_EXTRA_LSQ_INFO),

  toggleSendHardCopy: () => createAction(ActionType.TOGGLE_SEND_HARD_COPY),

  toggleSendTextNotification: () =>
    createAction(ActionType.TOGGLE_SEND_TEXT_NOTIFICATION),

  addShareOrderEmail: () => createAction(ActionType.ADD_SHARE_ORDER_EMAIL),

  dismissOrderAlert: () => createAction(ActionType.DISMISS_ORDER_ALERT),

  dismissSpecialOrderAlert: () =>
    createAction(ActionType.DISMISS_SPECIAL_ORDER_ALERT),

  setPaymentMethod: (paymentMethod: PaymentMethod) =>
    createAction(ActionType.SET_PAYMENT_METHOD, paymentMethod),

  setLeftSearchResultsPage: () =>
    createAction(ActionType.SET_LEFT_SEARCH_RESULTS_PAGE),

  setLeftResaleTransactionPage: () =>
    createAction(ActionType.SET_LEFT_RESALE_TRANACTION_PAGE),

  setExistingOrderInfo: (orderInfo: ExistingOrderInfo) =>
    createAction(ActionType.SET_EXISTING_ORDER_INFO, orderInfo),

  stopUpdatingExistingOrder: () =>
    createAction(ActionType.STOP_UPDATING_EXISTING_ORDER),

  setSelectedDocument: (uploadedDocument: File) =>
    createAction(ActionType.SET_SELECTED_DOCUMENT, uploadedDocument),

  setSelectedDocumentType: (documentType: OrderAttachmentDocumentType) =>
    createAction(ActionType.SET_SELECTED_DOCUMENT_TYPE, documentType),

  clearSelectedDocument: () => createAction(ActionType.CLEAR_SELECTED_DOCUMENT),
};

export type Action = ActionsUnion<typeof Actions>;
