import * as React from "react";
import { createStyles, makeStyles, alpha } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import classNames from "classnames";
import { NAVBAR_HEIGHT } from "../Navbar/shared";

// Use a higher z-index than all other elements, including modals, snackbars,
// and tooltips. This component should overlay any other part of the app.
export const OVERLAY_WRAPPER_Z_INDEX = 2000;

const useStyles = makeStyles(({ custom: { colors } }) =>
  createStyles({
    root: {
      top: NAVBAR_HEIGHT,
      flexGrow: 1,
      height: "100%",
      position: "fixed",
      zIndex: OVERLAY_WRAPPER_Z_INDEX,
      textAlign: "center",
      width: "100%",
    },
    backgroundOverlayLayer: {
      // The overlay's background should be slightly transparent. Use `alpha` to
      // convert the background colors to rgba.
      background: `linear-gradient(to bottom right, ${alpha(
        colors.primary,
        0.96
      )} 35%, ${alpha(colors.primaryMedium, 0.96)})`,
    },
    /**
     * Prevent the body from scrolling while the overlay is open. Without this, the background
     * could scroll, causing the wrapper not to feel like a modal.
     */
    "@global": {
      body: {
        overflow: "hidden",
      },
    },
  })
);

export const OverlayWrapper: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Fade in timeout={500}>
      <div className={classNames(classes.root, classes.backgroundOverlayLayer)}>
        {children}
      </div>
    </Fade>
  );
};
