import { FormValues } from "../../utils/reduxForm";
import { FormFieldNameMap } from "@homewisedocs/client-utils/lib/form";

export type ECheckFormFieldName =
  | "firstName"
  | "lastName"
  | "bankRoutingNumber"
  | "bankAccountNumber"
  | "agreedToPolicy";

export type ECheckFormValues = FormValues<ECheckFormFieldName>;

export const ECheckFormFieldNames: FormFieldNameMap<ECheckFormFieldName> = {
  firstName: "firstName",
  lastName: "lastName",
  bankRoutingNumber: "bankRoutingNumber",
  bankAccountNumber: "bankAccountNumber",
  agreedToPolicy: "agreedToPolicy",
};

export type CreditCardBillingInformationFieldNames =
  | "street"
  | "city"
  | "state"
  | "zip"
  | "country"
  | "cardHolderEmail";

export const BillingInformationFieldNames: FormFieldNameMap<CreditCardBillingInformationFieldNames> =
  {
    street: "street",
    city: "city",
    state: "state",
    zip: "zip",
    country: "country",
    cardHolderEmail: "cardHolderEmail",
  };

export type CreditCardFormFieldNames =
  | "ccNumber"
  | "ccExpMonth"
  | "ccExpYear"
  | "ccFirstName"
  | "ccLastName"
  | "ccCVV"
  | "agreedToPolicy";

export type CreditCardFormValues = FormValues<CreditCardFormFieldNames>;

export const CreditCardFieldNames: FormFieldNameMap<CreditCardFormFieldNames> =
  {
    ccNumber: "ccNumber",
    ccExpMonth: "ccExpMonth",
    ccExpYear: "ccExpYear",
    ccFirstName: "ccFirstName",
    ccLastName: "ccLastName",
    ccCVV: "ccCVV",
    agreedToPolicy: "agreedToPolicy",
  };

type FieldNames =
  | CreditCardBillingInformationFieldNames
  | CreditCardFormFieldNames
  | "payNow";

export const PayNowFieldName: FormFieldNameMap<"payNow"> = {
  payNow: "payNow",
};

export type PaymentFormValues = FormValues<FieldNames>;

export const paymentFormName = "Payment";

export enum PayNowValue {
  YES = "yes",
  NO = "no",
}
